.reservation-list-container {
  min-height: 100vh;
  background-size: cover;
  width: 100%;
  margin-left: 0px !important;
  overflow: hidden;
}

.reservation-list-container .filter-container {
  background-color: white;
  border-radius: 3px;
  padding: 1px 3px;
}

.reservation-list-container .filter-container>div:nth-child(1) {
  width: 200px;
  display: inline-block;
  margin-right: 5px;
}

.reservation-list-container .filter-container .show-date {
  display: inline-block;
}

.reservation-list-container .filter-container .dialog-container>button {
  margin-right: 7px;
}

.datatable-react {
  border: 1px solid #dddddd82;
}

.reservation-list-container .Ext-ended {
  padding: 5px;
  border-bottom: 1px solid #dddddd82;
  font-size: 0.7rem;
}

.reservation-list-container .Ext-ended>div {
  width: 80%;
  background: #dbecf969;
  padding: 5px 10px;
  margin: 0 auto;
  overflow-x: auto;
}

/* .card-body datatable-react {
  
  } */

.reservation-list-container .Ext-ended table {
  border: 1px solid #ddd;
}

.reservation-list-container .Ext-ended h6 {
  font-size: 1rem;
  text-decoration: underline;
  font-weight: 800;
  letter-spacing: 2px;
  color: #00419d;
}

.reservation-list-container .Ext-ended .students p {
  margin-bottom: 0;
}

@media (min-width: 567px) {
  .reservation-list-container .Ext-ended {
    font-size: 1rem;
  }

  .reservation-list-container .Ext-ended h6 {
    font-size: 1.4rem;
  }
}

.rdw-dropdownoption-default {
  color: black;

}

.editorClassName .public-DraftStyleDefault-block {
  margin: 0 !important
}

#editor iframe {
  min-width: 20% !important;
}


/* .emailEditorStyle{
    min-width: 40% !important;
  } */

.emailEditorButtStyle {
  background-color: white;
  padding: 6px;
  font-style: initial;
}

.Donutcss {
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 100px;
  height: 100%;


}

.YourContentCustomClass {
  padding: 0 !important;
  margin-left: 14.5%;
}

/* .YourModalCustomClass{
    padding: 0 !important;
    margin-left: 14.5%;
  } */
/* .modalBody123{
    margin: 0 !important;
    max-width: inherit;
  } */


/* @media (min-width: 1800px) {
    .email-editor-style-1920{
      max-height:71vh,
    }
    } */

@media only screen and (max-width: 1920px) {
  .email-editor-style-1920 {
    max-height: 71vh,
  }
}

.Email_Modal {
  margin: 0 !important;
  max-width: inherit;
  /* padding: 0 !important;
      margin-left: 14.5%; */
}

.Email_Modal .modal-body {
  color: #ffffff;
}

.Email_Modal .modal-header {
  color: #ffffff;
}

.modal-content {
  padding: 0 !important;
  /* margin-left: 14.5%; */
}
