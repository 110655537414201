.blueBg {
  background: #729fcf !important;
  color: white !important;
}

.blueBg.rightCol {
  background: #1c6bcf !important;
}

.mt-10 {
  margin-top: 30vh !important;
}

@keyframes pulse {
  from {
    transform: scale(1);
  }
  50% {
    transform: scale(0.85);
  }
  to {
    transform: scale(1);
  }
}

/* .fe-pulse {
  animation-name: pulse;
  animation-duration: 2s;
  animation-iteration-count: infinite;
} */

.leftButtons {
  padding-left: 20px;
  padding-right: 10px;
}

.leftButtons > li {
  list-style: none;
}

.fullHeightPanes {
  min-height: 100%;
}

.centerCards {
  background: #e7f2f6;
  color: #0a2c56;
  /* min-height: 600px !important; */
  /* margin-left: 20px;
  margin-right: 20px; */
  border-radius: 00px 00px 0px 0px;
  -webkit-border-radius: 0px 0px 0px 0px;
  -moz-border-radius: 0px 0px 0px 0px;
  padding: 0px !important;
  overflow-x: hidden;
}

.historyCenterCards {
  background: #e7f2f6;
  color: #0a2c56;
  min-height: 600px !important;
  /* margin-left: 20px;
  margin-right: 20px; */
  border-radius: 00px 00px 0px 0px;
  -webkit-border-radius: 0px 0px 0px 0px;
  -moz-border-radius: 0px 0px 0px 0px;
  padding: 0px !important;
  overflow-x: hidden;
}

.rightCol .columnHeading {
  background: #00419d;
  padding: 20px;
}

.columnHeading {
  display: block;
  background: #1c6bcf;
  padding: 10px;
  font-size: 20px;
  text-align: center;
  color: white;
  letter-spacing: 1px;
}

.centerDivHeading {
  display: block;
  background: rgb(28, 107, 207);
  padding: 10px;
  font-size: 18px;
  text-align: center;
  color: white;
  letter-spacing: 1px;
}

.centerDivFooter {
  display: block;
  background: #1c6bcf;
  padding: 5px;
  /* font-size: 18px; */
  font-size: 1.2em;
  text-align: center;
  color: white;
  letter-spacing: 1px;
  /* margin-left: 20px;
  margin-right: 20px; */
  border-radius: 0px 0px 0px 0px;
  overflow: visible;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.lightBlueButton {
  background: #1c6bcf !important;
  color: white;
  border-radius: 0px 0px 0px 0px;
  padding-top: 0px;
  padding-bottom: 0px;
}

.crop-container {
  position: fixed;
  top: 20%;
  left: 25%;
  padding: 1rem;
  height: 50%;
  width: 50%;
  border-radius: 12px;
  z-index: 99999999999 !important;
  opacity: 1 !important;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.imageContainer {
  border: 2px dashed #fff;
  height: 140px;
  width: 140px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 0px !important;
}

.imagePreview {
  margin-left: 16px;
}

.uploadImage {
  position: absolute;
  margin: 0;
  padding: 0;
  outline: none;
  opacity: 0;
  width: 100%;
  height: 100%;
}

.squareAuthParent{
  width: 90%;
  padding-top: 50px;
  display: flex;
  align-items: center;
}

.squareAuthParent div {
  width: 250px;
  justify-content: space-between;
  display: flex;
  align-items: baseline;
  padding-left: 5px;
  padding-right: 5px;
}

.squareAuthParent div p {
  color: #ffff;
  display: inline-block;
}

.squareAuthBtn{
  width: 250px;
  height: 50px;
  color: #ffff;
  background: #1c6bcf;
  border-radius: 10px;
  border: none;
}

.check-icon {
  margin-left: 20px !important;
  color: #0ede4b;
  font-size: 32px !important;
}

.squareAuthBtn:hover {
  background:#5b9bd3;
}

.refundGiftCardRefundingValues {
  border: 1px solid white;
  width: 100%;
  height: 37px;
  padding: 5px 10px 5px 10px;
  border-radius: 5px;
}


.squareAuthBtn:focus {
  outline: none;
  border: 1px solid #ffff;
}

.uploadImage input {
  opacity: 0;
  overflow: hidden;
  z-index: -1;
}

.uploadImage input + label {
  font-size: 1.25em;
  font-weight: 500;
  color: red;
  display: inline-block;
}

.buttonOutMain {
  margin-top: 20px;
  display: flex;
  width: 400px;
  justify-content: space-evenly;
  flex-direction: row;
  font-size: 26px;
}

.buttonOutMain > div {
  background-color: #00419d;
  padding: 0.5rem 1rem;
  border-radius: 5px;
}

.controls {
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 50%;
  transform: translateX(-50%);
  height: 80px;
  display: flex;
  align-items: center;
}

.slider {
  padding: 22px 0px;
}

.lightBlueButton:hover {
  background-image: linear-gradient(
    bottom,
    rgb(79, 142, 191) 0%,
    rgb(102, 166, 214) 100%
  );
  background-image: -o-linear-gradient(
    bottom,
    rgb(79, 142, 191) 0%,
    rgb(102, 166, 214) 100%
  );
  background-image: -moz-linear-gradient(
    bottom,
    rgb(79, 142, 191) 0%,
    rgb(102, 166, 214) 100%
  );
  background-image: -webkit-linear-gradient(
    bottom,
    rgb(79, 142, 191) 0%,
    rgb(102, 166, 214) 100%
  );
  background-image: -ms-linear-gradient(
    bottom,
    rgb(79, 142, 191) 0%,
    rgb(102, 166, 214) 100%
  );
  background-image: -webkit-gradient(
    linear,
    left bottom,
    left top,
    color-stop(0, rgb(79, 142, 191)),
    color-stop(1, rgb(102, 166, 214))
  );
}

.jump-type-group {
  font-weight: bold;
}

.customButton.active {
  background: #0a2c56 !important;
  color: white !important;
}

.customButton {
  padding: 10px;
  background: white;
  color: #0a2c56;
  width: 100%;
  display: block;
  font-size: 22px;
  padding: 14px !important;
  margin: auto;
  margin-top: 0px;
  margin-bottom: 20px;
  white-space: initial !important;
  border: 1px solid #2d6898;
  background-image: linear-gradient(
    bottom,
    rgb(73, 132, 180) 0%,
    rgb(97, 155, 203) 100%
  );
  background-image: -o-linear-gradient(
    bottom,
    rgb(73, 132, 180) 0%,
    rgb(97, 155, 203) 100%
  );
  background-image: -moz-linear-gradient(
    bottom,
    rgb(73, 132, 180) 0%,
    rgb(97, 155, 203) 100%
  );
  background-image: -webkit-linear-gradient(
    bottom,
    rgb(73, 132, 180) 0%,
    rgb(97, 155, 203) 100%
  );
  background-image: -ms-linear-gradient(
    bottom,
    rgb(73, 132, 180) 0%,
    rgb(97, 155, 203) 100%
  );
  background-image: -webkit-gradient(
    linear,
    left bottom,
    left top,
    color-stop(0, rgb(73, 132, 180)),
    color-stop(1, rgb(97, 155, 203))
  );
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0px 6px 0px #2b638f, 0px 3px 15px rgba(0, 0, 0, 0.4),
    inset 0px 1px 0px rgba(255, 255, 255, 0.3),
    inset 0px 0px 3px rgba(255, 255, 255, 0.5);
  -moz-box-shadow: 0px 6px 0px #2b638f, 0px 3px 15px rgba(0, 0, 0, 0.4),
    inset 0px 1px 0px rgba(255, 255, 255, 0.3),
    inset 0px 0px 3px rgba(255, 255, 255, 0.5);
  box-shadow: 0px 6px 0px #2b638f, 0px 3px 15px rgba(0, 0, 0, 0.4),
    inset 0px 1px 0px rgba(255, 255, 255, 0.3),
    inset 0px 0px 3px rgba(255, 255, 255, 0.5);
}

.customButton:hover {
  background-image: linear-gradient(
    bottom,
    rgb(79, 142, 191) 0%,
    rgb(102, 166, 214) 100%
  );
  background-image: -o-linear-gradient(
    bottom,
    rgb(79, 142, 191) 0%,
    rgb(102, 166, 214) 100%
  );
  background-image: -moz-linear-gradient(
    bottom,
    rgb(79, 142, 191) 0%,
    rgb(102, 166, 214) 100%
  );
  background-image: -webkit-linear-gradient(
    bottom,
    rgb(79, 142, 191) 0%,
    rgb(102, 166, 214) 100%
  );
  background-image: -ms-linear-gradient(
    bottom,
    rgb(79, 142, 191) 0%,
    rgb(102, 166, 214) 100%
  );
  background-image: -webkit-gradient(
    linear,
    left bottom,
    left top,
    color-stop(0, rgb(79, 142, 191)),
    color-stop(1, rgb(102, 166, 214))
  );
}

.transferButton {
  padding: 10px;
  background: white;
  color: #0a2c56;
  width: 100%;
  display: block;
  font-size: 22px;
  padding: 14px !important;
  margin: auto;
  margin-top: 0px;
  margin-bottom: 20px;
  white-space: initial !important;
  border: 1px solid #2d6898;
  background-image: linear-gradient(
    bottom,
    rgb(73, 132, 180) 0%,
    rgb(97, 155, 203) 100%
  );
  background-image: -o-linear-gradient(
    bottom,
    rgb(73, 132, 180) 0%,
    rgb(97, 155, 203) 100%
  );
  background-image: -moz-linear-gradient(
    bottom,
    rgb(73, 132, 180) 0%,
    rgb(97, 155, 203) 100%
  );
  background-image: -webkit-linear-gradient(
    bottom,
    rgb(73, 132, 180) 0%,
    rgb(97, 155, 203) 100%
  );
  background-image: -ms-linear-gradient(
    bottom,
    rgb(73, 132, 180) 0%,
    rgb(97, 155, 203) 100%
  );
  background-image: -webkit-gradient(
    linear,
    left bottom,
    left top,
    color-stop(0, rgb(73, 132, 180)),
    color-stop(1, rgb(97, 155, 203))
  );
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0px 0px 0px #2b638f, 0px 3px 15px rgba(0, 0, 0, 0.4),
    inset 0px 1px 0px rgba(255, 255, 255, 0.3),
    inset 0px 0px 3px rgba(255, 255, 255, 0.5);
  -moz-box-shadow: 0px 0px 0px #2b638f, 0px 3px 15px rgba(0, 0, 0, 0.4),
    inset 0px 1px 0px rgba(255, 255, 255, 0.3),
    inset 0px 0px 3px rgba(255, 255, 255, 0.5);
  box-shadow: 0px 0px 0px #2b638f, 0px 3px 15px rgba(0, 0, 0, 0.4),
    inset 0px 1px 0px rgba(255, 255, 255, 0.3),
    inset 0px 0px 3px rgba(255, 255, 255, 0.5);
}

.transferButton:hover {
  background-image: linear-gradient(
    bottom,
    rgb(79, 142, 191) 0%,
    rgb(102, 166, 214) 100%
  );
  background-image: -o-linear-gradient(
    bottom,
    rgb(79, 142, 191) 0%,
    rgb(102, 166, 214) 100%
  );
  background-image: -moz-linear-gradient(
    bottom,
    rgb(79, 142, 191) 0%,
    rgb(102, 166, 214) 100%
  );
  background-image: -webkit-linear-gradient(
    bottom,
    rgb(79, 142, 191) 0%,
    rgb(102, 166, 214) 100%
  );
  background-image: -ms-linear-gradient(
    bottom,
    rgb(79, 142, 191) 0%,
    rgb(102, 166, 214) 100%
  );
  background-image: -webkit-gradient(
    linear,
    left bottom,
    left top,
    color-stop(0, rgb(79, 142, 191)),
    color-stop(1, rgb(102, 166, 214))
  );
}

.customRightButton {
  padding: 10px;
  background: white;
  color: #0a2c56;
  width: 100%;
  display: block;
  font-size: 22px;
  padding: 11px !important;
  margin: auto;
  margin-top: 1px;
  /* margin-bottom: 12px; */
  white-space: initial !important;
  border: 1px solid #2d6898;
  background-image: linear-gradient(
    bottom,
    rgb(73, 132, 180) 0%,
    rgb(97, 155, 203) 100%
  );
  background-image: -o-linear-gradient(
    bottom,
    rgb(73, 132, 180) 0%,
    rgb(97, 155, 203) 100%
  );
  background-image: -moz-linear-gradient(
    bottom,
    rgb(73, 132, 180) 0%,
    rgb(97, 155, 203) 100%
  );
  background-image: -webkit-linear-gradient(
    bottom,
    rgb(73, 132, 180) 0%,
    rgb(97, 155, 203) 100%
  );
  background-image: -ms-linear-gradient(
    bottom,
    rgb(73, 132, 180) 0%,
    rgb(97, 155, 203) 100%
  );
  background-image: -webkit-gradient(
    linear,
    left bottom,
    left top,
    color-stop(0, rgb(73, 132, 180)),
    color-stop(1, rgb(97, 155, 203))
  );
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0px 6px 0px #2b638f, 0px 3px 15px rgba(0, 0, 0, 0.4),
    inset 0px 1px 0px rgba(255, 255, 255, 0.3),
    inset 0px 0px 3px rgba(255, 255, 255, 0.5);
  -moz-box-shadow: 0px 6px 0px #2b638f, 0px 3px 15px rgba(0, 0, 0, 0.4),
    inset 0px 1px 0px rgba(255, 255, 255, 0.3),
    inset 0px 0px 3px rgba(255, 255, 255, 0.5);
  box-shadow: 0px 6px 0px #2b638f, 0px 3px 15px rgba(0, 0, 0, 0.4),
    inset 0px 1px 0px rgba(255, 255, 255, 0.3),
    inset 0px 0px 3px rgba(255, 255, 255, 0.5);
}

.customRightButton:hover {
  background-image: linear-gradient(
    bottom,
    rgb(79, 142, 191) 0%,
    rgb(102, 166, 214) 100%
  );
  background-image: -o-linear-gradient(
    bottom,
    rgb(79, 142, 191) 0%,
    rgb(102, 166, 214) 100%
  );
  background-image: -moz-linear-gradient(
    bottom,
    rgb(79, 142, 191) 0%,
    rgb(102, 166, 214) 100%
  );
  background-image: -webkit-linear-gradient(
    bottom,
    rgb(79, 142, 191) 0%,
    rgb(102, 166, 214) 100%
  );
  background-image: -ms-linear-gradient(
    bottom,
    rgb(79, 142, 191) 0%,
    rgb(102, 166, 214) 100%
  );
  background-image: -webkit-gradient(
    linear,
    left bottom,
    left top,
    color-stop(0, rgb(79, 142, 191)),
    color-stop(1, rgb(102, 166, 214))
  );
}

.customRightButtonPulse {
  padding: 10px;
  background: white;
  color: #0a2c56;
  width: 100%;
  display: block;
  font-size: 22px;
  padding: 11px !important;
  margin: auto;
  margin-top: 1px;
  /* margin-bottom: 12px; */
  white-space: initial !important;
  border: 1px solid #2d6898;
  background-image: linear-gradient(
    bottom,
    rgb(73, 132, 180) 0%,
    rgb(97, 155, 203) 100%
  );
  background-image: -o-linear-gradient(
    bottom,
    rgb(73, 132, 180) 0%,
    rgb(97, 155, 203) 100%
  );
  background-image: -moz-linear-gradient(
    bottom,
    rgb(73, 132, 180) 0%,
    rgb(97, 155, 203) 100%
  );
  background-image: -webkit-linear-gradient(
    bottom,
    rgb(73, 132, 180) 0%,
    rgb(97, 155, 203) 100%
  );
  background-image: -ms-linear-gradient(
    bottom,
    rgb(73, 132, 180) 0%,
    rgb(97, 155, 203) 100%
  );
  background-image: -webkit-gradient(
    linear,
    left bottom,
    left top,
    color-stop(0, rgb(73, 132, 180)),
    color-stop(1, rgb(97, 155, 203))
  );
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0px 6px 0px #2b638f, 0px 3px 15px rgba(0, 0, 0, 0.4),
    inset 0px 1px 0px rgba(255, 255, 255, 0.3),
    inset 0px 0px 3px rgba(255, 255, 255, 0.5);
  -moz-box-shadow: 0px 6px 0px #2b638f, 0px 3px 15px rgba(0, 0, 0, 0.4),
    inset 0px 1px 0px rgba(255, 255, 255, 0.3),
    inset 0px 0px 3px rgba(255, 255, 255, 0.5);
  box-shadow: 0px 6px 0px #2b638f, 0px 3px 15px rgba(0, 0, 0, 0.4),
    inset 0px 1px 0px rgba(255, 255, 255, 0.3),
    inset 0px 0px 3px rgba(255, 255, 255, 0.5);
  transform: scale(1);
  animation: pulse2 2s infinite;
}

@keyframes pulse2 {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
  }
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }
  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}

.columnBody {
  padding: 30px 10px;
  text-align: center;
}

.columnBody h5 {
  margin-bottom: 25px;
  margin-top: 25px;
}

.rightButton {
  background: #59c5dc;
  color: white;
  font-weight: 500 !important;
  font-size: 16px !important;
}

.checkboxCol {
  width: 0;
}

.viewHistory{
  border-radius: 4px;
  background-color: #A7F6FF;
  color: #082764
}

.customModal {
  display: none;
}

.customModal.visible {
  background: rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 0px;
  left: 0;
  display: flex;
  width: 100%;
  height: 100%;
  z-index: 4;
}
.add_btn {
  position: absolute;
  top: 23px;
  right: 73px;
  background: none;
  border: none;
  color: blue;
  cursor: pointer;
  width: 30px;
  text-align: center;
  box-sizing: border-box;
  background-color: transparent;
  border-radius: 50%;
  padding: 2px;
}
.add_btn:hover {
  box-sizing: border-box;
  background-color: aliceblue;
  border-radius: 50%;
  padding: 2px;
}
.send_email_btn {
  position: absolute;
  top: 23px;
  right: 102px;
  background: none;
  border: none;
  color: blue;
  cursor: pointer;
  width: 30px;
  text-align: center;
  box-sizing: border-box;
  background-color: transparent;
  border-radius: 50%;
  padding: 2px;
}

.send_email_text {
  position: absolute;
  top: 48px;
  right: 40px;
  visibility: hidden;
}

.remove_jumper,
.distribute_amount {
  visibility: hidden;
  font-size: 14px;
}

.send_email_btn:hover {
  box-sizing: border-box;
  background-color: aliceblue;
  border-radius: 50%;
  padding: 2px;
}

.send_email_btn_primary:hover .send_email_text{
  visibility: visible;
} 
.showPaidInput.form-control{
  width: 60%;
  display: inline
}
.tandemStudentDetail{
  margin-bottom: 10px
}
.tandemStudentDetail > div{
  display: flex;
  align-items: center;
}
.jumperDetail {
  width: 100%;
  margin-left: 20px
}
.refundInput {
  margin-top: 10px;
}
.amountInput {
  padding: 0px 10px 0px 0px;
  margin-bottom: 10px;
}
.refundInput {
  display: flex;
  align-items: center
}

.kzrUd{
  border-bottom: 1px solid lightgray;
}

.data_category .MUpkg {
  padding: 0 10px;
}

.data_category .rdt_TableRow {
  padding: 0 10px;
}

.eZWiNK {
  display: none !important;
}

.data_category .bboORw {
  padding-left: 10px;
}
.trash_icon:hover .remove_jumper {
  visibility: visible;
}

.remove_icon:hover .distribute_amount {
  visibility: visible;
}

.modalBodyContainer {
  width: 80%;
  max-width: 600px;
  min-height: 200px;
  margin: auto;
  background: rgb(0, 65, 157);
  color: #e7f2f6;
  padding: 40px;
  border-radius: 5px;
  font-size: 18px;
}

.modalBodyContainer .btn-link {
  color: white;
}

.modalForm {
  background: transparent;
  border: 0px;
  border-bottom: 1px solid #e7f2f6;
  width: 100%;
  display: inline-block;
  color: white;
}

.iconPostfix {
  position: absolute;
  top: 0px;
  right: 15px;
}

.lightColor {
  background-color: #e7f2f6 !important;
  color: #00419d;
}

.newBtn {
  border-radius: '4px';
  background-color: #A7F6FF;
  color: #082764;
}

.transferCategoryRadioBtn{
  display: flex;
  align-items: center;
}

.transferCategoryRadioBtn input{
  width: 15px;
  margin-right: 10px;
}

.transferCategoryRadioBtn input:focus{
  box-shadow: none;
}

.chargeTandemBtn { 
  color: #000 ;
  background-color: #efefef;
  border-color: #6c757d;
  width: max-content; 
  font-size: 14px;
  text-transform: lowercase;
  padding: 4px 10px;
}

.createButton {
  margin: auto;
  width: 150px;
  letter-spacing: 1px;
  font-size: 18px;
}

.modalBodyContainer .row {
  margin-top: 20px;
}

.smaller {
  font-size: 14px;
}

.rbc-event.rbc-selected {
  border: 3px solid rgb(218, 105, 102);
}

.rbc-event:focus {
  border: 3px solid rgb(218, 105, 102) !important;
}

.SingleDatePicker {
  width: 100%;
  background: transparent !important;
}

.SingleDatePickerInput__withBorder {
  width: 100%;
  background: transparent !important;
}

.DateInput {
  /* width:100% !important; */
  background: transparent !important;
  border: 0px;
  width: 45% !important;
}

.DateInput_input {
  border-bottom: 1px solid #e7f2f6 !important;
  padding: 11px 5px 9px !important;
  font-size: 18px !important;
  color: white !important;
}

.DateInput_input__focused,
.DateInput_input {
  background: transparent !important;
  border-top: 0px !important;
  border-left: 0px !important;
  border-right: 0px !important;
  color: white !important;
}

.SingleDatePickerInput__withBorder {
  border-radius: 0px !important;
  border: 1px solid transparent !important;
}

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #b2cae8;
}

::-moz-placeholder {
  /* Firefox 19+ */
  color: #b2cae8;
}

:-ms-input-placeholder {
  /* IE 10+ */
  color: #b2cae8;
}

:-moz-placeholder {
  /* Firefox 18- */
  color: #b2cae8;
}

.DayPickerKeyboardShortcuts_panel {
  color: black;
}

.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
  background: #00419d !important;
  border: 1px double #00419d !important;
}

.DayPickerKeyboardShortcuts_show__bottomRight {
  border-right: 33px solid #00419d !important;
}

.DateRangePickerInput {
  background-color: transparent !important;
}

.DateRangePickerInput_arrow_svg {
  fill: white !important;
}

.DateRangePickerInput__withBorder {
  border: 0px !important;
}

.modalSelect {
  background: blue !important;
  border: 0px !important;
  color: white !important;
  border-bottom: 1px solid white !important;
  width: 100% !important;
}

.rightColumnCards {
  color: black !important;
  margin-bottom: 5px;
  background: #bfd7f5 !important;
}

.rightCancelCards {
  color: black !important;
  margin-bottom: 5px;
  background: #aaafb4 !important;
}

.rightColumnCards .card-title {
  margin-top: 0px;
  margin-bottom: 5px !important;
}

.rightColumnCards .card-body {
  text-align: left !important;
}

/* .rightColumnCards .card-body{
  padding:1em !important;
} */

.suspended-body{
  background: #aaafb4 !important;
}

.rightColumnCards ol {
  padding: 0;
  padding-left: 20px;
  text-align: left;
  color: #2873cc;
  font-size: 14px;
}

.rightCancelCards {
  color: black !important;
  margin-bottom: 5px;
  background: #aaafb4 !important;
}

.rightCancelCards .card-title {
  margin-top: 0px;
  margin-bottom: 5px !important;
}

.rightCancelCards .card-body {
  text-align: left !important;
}

.rightCancelCards .card-body {
  padding: 1em !important;
}

.rightCancelCards ol {
  padding: 0;
  padding-left: 20px;
  text-align: left;
  color: #2873cc;
  font-size: 14px;
}

.jumpcard {
  margin-top: -1px;
  margin-bottom: 0px;
}

.jumpcardheader {
  padding: 0px;
  max-height: 30px;
  text-align: center;
  vertical-align: center;
  padding: 0.15rem 1.25rem !important;
}

.carouselslide {
  min-height: 300px !important;
  min-width: 400px !important;
}

/* custom modal style */

/* create-giftcard-modal */

.create-giftcard-modal.modal-header {
  padding-left: 0 !important;
  justify-content: center;
}

#modal-main .modal-content {
  background: rgb(0, 65, 157);
  border: 0px;
  color: #e7f2f6;
}

#modal-main table,
#modal-report table {
  color: #e7f2f6;
}

#modal-main .modal-content .form-control {
  background: rgb(0, 65, 157);
  color: #e7f2f6;
}

.itemizedDetail td{
  font-size: 14px;
  padding: 5px;
}

.itemizedDetail td .form-control {
  font-size: 14px;
  padding: 4px 6px;

}

#modal-waiver .modal-content {
  background: rgb(0, 65, 157);
  border: 0px;
  color: #e7f2f6;
  min-width: 800px !important;
  width: 900px;
  max-width: none !important;
}

#modal-report .modal-content {
  background: rgb(0, 65, 157);
  border: 0px;
  color: #e7f2f6;
  min-width: 800px !important;
  width: 1300px;
  max-width: none !important;
}

#modal-report .modal-content .form-control {
  background: rgb(0, 65, 157);
  color: #e7f2f6;
}

.react-select__control {
  background: rgb(0, 65, 157);
  color: #e7f2f6;
}

.m-t-1-rem {
  margin-top: 1rem;
}

.m-t-2-rem {
  margin-top: 2rem;
}

.carousel {
  width: 100%;
}

.carousel li > div:first-of-type {
  position: static !important;
}

.carousel ul:first-child > li {
  height: 100% !important;
}

/* .carousel li .col {
  width: 50%;
} */

.jumpcard.card li,
.jumpcard.card li .btn {
  /* width: 100%; */
}

.carousel .carousel__slide-focus-ring {
  outline: none !important;
}

.hide {
  display: none !important;
}

.carousel__back-button {
  position: absolute;
  top: 50%;
  left: 0;
  margin-left: -30px;
}

.carousel__next-button {
  position: absolute;
  top: 50%;
  right: 0;
  margin-right: -30px;
}

.hamBurger-menu {
  display: none;
}

.hamBurger-right {
  display: none;
}

/* graph */
.graphKeysCon {
  border: 1px solid grey;
  height: fit-content;
}
.keyRow {
  padding: 5px 15px;
  display: flex;
  align-items: center;
}
.colorBox {
  width: 35px;
  height: 15px;
  background: #dc3545;
  margin-right: 8px;
}
.colorBlue {
  background: #5b9bd3;
}
.colorYellow {
  background: #dbb954;
}

/* catagory button */
.catagorybtn {
  display: flex;
  border: none;
  font-size: 12px;
  position: absolute;
  left: 120px;
  color: black;
  z-index: 1;
  top: 25px;
  cursor: pointer;
}
.inventoryTable {
  /* width: 500px;
  margin-left: 5%; */
  margin: 0 auto 20px auto;
  width: 90%;
}
.categoryTable {
  width: 500px;
  margin-left: 5%;
  position: relative;
}
@media only screen and (max-width: 600px) {
  .mt-10 {
    margin-top: 12vh !important;
  }
  .menu-collapse {
    display: none;
  }
  .hamBurger-menu {
    /* padding-left: 86%;
    padding-bottom: 14px;
    display: block;
    padding-top:20px; */
    padding-bottom: 14px;
    display: block;
    padding-top: 12px;
    left: 34px;
    position: absolute;
    z-index: 9999999;
  }
  .hamBurger-right {
    display: block;
    padding: 0 !important;
    position: absolute;
    top: 14px;
    right: 30px;
    z-index: 9999999;
  }
  .DataTable-paper-152 {
    width: 86%;
    border: 0;
    height: 85% !important;
    margin: 2% auto;
    min-width: 333px;
    max-height: 85% !important;
    overflow-y: auto;
    border-radius: 3px;
    background-color: #e7f2f6;
  }
  .mobileLeftBar {
    /* display: block; */
    position: absolute;
    left: 0;
    z-index: 9999;
    top: 67px;
    width: 235px;
    height: 150px;
    -webkit-transition: width 2s, height 4s;
    /* For Safari 3.1 to 6.0 */
    transition: width 2s, height 4s;
  }
  .hideSideBar {
    /* display: none; */
    width: 0px;
    height: 0px;
  }
  .hide-Panel {
    opacity: 0;
  }
  .show-panel {
    opacity: 1;
    transition: opacity 2s;
  }
  .mobileRightBar {
    display: block;
    position: absolute;
    right: 0;
    z-index: 9999;
  }
}

.big-calender {
  width: 90%;
}

.account-selector {
  width: 90%;
  margin-top: 10px;
  margin-left: 15%;
}

.account-header {
  color: inherit;
  height: 48px;
  /* display: table-row; */
  outline: none;
  vertical-align: middle;
  text-align: center;
  background-color: #1c6bcf;
  padding: 10px;
}

.account-header span {
  /* color: #28a6e6; */
  color: #e7f2f6;
  width: 50%;
  font-size: 22px;
  text-align: center;
  font-weight: 500;
  text-transform: uppercase;
}

/* .css-1vg1utd {
  top: 100%;
  background-color: #E7F2F6 !important;
  border-radius: 0px !important;
  box-shadow: 0 0 0 1px hsla(0,0%,0%,0.1), 0 4px 11px hsla(0,0%,0%,0.1);
  margin-bottom: 8px;
  margin-top: 0px !important;
  position: absolute;
  width: 100%;
  z-index: 1;
  box-sizing: border-box;
  border-bottom: 1px dotted pink;
  color: blue;
  padding: 20px;
  height: 570px !important;
  overflow: hidden;
} */

.custom-header {
  background-color: #e7f2f6 !important;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.custom-name {
  padding-left: 53px !important;
  font-weight: 500 !important;
  font-size: 20px !important;
  color: blue !important;
}

.custom-balance {
  padding-left: 0px !important;
  font-weight: 500 !important;
  font-size: 20px !important;
  color: blue !important;
}

/*
.css-qlx21k {
  background-color: #E7F2F6 !important;
  border-radius: 0px !important;
} */

.HorizontalButtons-paper-98 {
  width: 86% !important;
  margin: 2% auto;
  padding: 0;
  margin-top: 3.2%;
  border-radius: 3px;
  margin-bottom: 0%;
  background-color: #e7f2f6;
}

/* Jumper Transition Effect */

.fade-enter {
  opacity: 0.01;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 800ms ease-in;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0.01;
  transition: opacity 800ms ease-in;
}

/* Jumper Transition End */

.fade-Out {
  opacity: 0 !important;
  transition: 1500ms;
  transition-timing-function: ease;
}

.fade-in {
  opacity: 1;
  transition: opacity 1500ms ease-in;
}

.fade {
  -webkit-transition: opacity 0.15s linear !important;
  -o-transition: opacity 0.15s linear !important;
  transition: opacity 0.55s linear !important;
}

.fade-popup {
  width: 100%;
  transition: width 2s ease 0s;
}

/* Load Manager Transition  */

.load-manger-appear {
  opacity: 0.01;
}

.load-manger-appear.load-manger-appear-active {
  opacity: 1;
  transition: opacity 800ms ease-in;
}

.load-manger-leave {
  opacity: 1;
}

.load-manger-leave.load-manger-leave-active {
  opacity: 0.01;
  transition: opacity 800ms ease-in;
}

/* Load Manager transition End */

.custom-card-body {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 0.2rem 0.5rem !important;
}

.admin-selector {
  width: 90%;
  margin-top: 10px;
  margin-left: 10%;
}

.MuiTypography-body1-10 {
  color: #fff !important;
  font-size: 0.875rem;
  font-weight: 400;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  line-height: 1.46429em;
}

.ViewAccounts-totalSection-246 {
  color: #e7f2f6;
  height: auto !important;
  margin: 10px auto;
  border: 1px solid #e7f2f6;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  border-left: 0;
  border-right: 0;
  text-transform: capitalize;
}

.rating-input {
  /* display: block; */
  margin-left: 10px;
  width: 41%;
  /* height: calc(2.25rem + 2px); */
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  -webkit-transition: border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  -o-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
}

.jumper-load {
  margin: 0;
  padding: 0;
  display: inline-block;
  /* padding:1px; */
}

/*
.ranks li {
  display: inline-block;
    border: 1px solid #CCC;
    padding: 5px 10px;
    margin-right: 5px;
    background: #a0c2df;
    font-size: 12px;
    padding: 3px;
    border-radius: 5px;
    margin-bottom: 5px;
} */

/* .jumper-load  li{
  display: inline-block;
  margin-right: 5px;
  background-color: rgb(160, 194, 223);
  font-size: 12px;
  padding: 3px;
  border-radius: 5px;
  margin-bottom: 1px;
} */

/* .InvoiceByCategory-sectionHeading-231 {
  color: #03173F;
  padding: 1rem 0em 1rem 2.5em;
  font-size: 22px !important;
  font-weight: 500;
  font-family: Roboto;
  border-bottom: 0.5px solid rgba(88,88,140,0.2);
  text-transform: capitalize;
  padding-left: 45px !important;

}

.InvoiceByCategory-sectionHeading-251 {
  color: #03173F;
  padding: 1rem 0em 1rem 2.5em;
  font-size: 22px !important;
  font-weight: 500;
  font-family: Roboto;
  border-bottom: 0.5px solid rgba(88,88,140,0.2);
  text-transform: capitalize;
  padding-left: 45px !important;
} */

.mobileShow {
  display: none;
}

.webShow {
  display: block;
}

.selectBox svg {
  color: #000;
}

.Axis:first-child .ticks {
  transform: translate3d(0px, -12px, 0px);
}

.Axis:last-child .ticks {
  transform: translate3d(14px, 0px, 0px);
}

.chartSelect ul {
  background: #d8d8d8;
}

/* Smartphone Portrait and Landscape */

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .middle-container {
    /* width: 80.666% !important; */
    width: 100% !important;
  }

  .mobileShow {
    display: inline;
  }
  .webShow {
    display: none;
  }
  .carousel__back-button {
    margin-left: 4px;
  }
  .carousel__next-button {
    margin-right: 2px;
  }
  .right-menu {
    padding: 0px;
    position: absolute;
    right: 0;
    top: 67px;
    height: 100%;
    width: 235px;
    height: 150px;
    -webkit-transition: width 2s, height 4s;
    /* For Safari 3.1 to 6.0 */
    transition: width 2s, height 4s;
  }
  .modal.show .modal-dialog {
    margin-top: 60px;
  }
  .createButton {
    margin: auto;
    width: 107px;
    letter-spacing: 1px;
    font-size: 18px;
  }
  .big-calender {
    width: 100%;
  }
  .MuiGrid-container-1 {
    overflow: hidden;
    width: 100%;
  }
  .MuiGrid-grid-xs-8-37 {
    flex-grow: 0;
    max-width: 99.666667%;
    flex-basis: 66.666667%;
  }
  .blueBg.rightCol {
    background: #1c6bcf !important;
    z-index: 9999;
    height: 666px;
  }
  .MuiGrid-grid-xs-8-37 {
    flex-grow: 0;
    max-width: 100% !important;
    flex-basis: 66.666667%;
  }
  .HorizontalButtons-paper-133 {
    width: 100% !important;
    margin: 2% auto;
    padding: 0;
    margin-top: 3.2%;
    border-radius: 3px;
    margin-bottom: 0%;
    background-color: #e7f2f6;
  }
  .HorizontalButtons-paper-98 {
    width: 100% !important;
    margin: 2% auto;
    padding: 0;
    margin-top: 3.2%;
    border-radius: 3px;
    margin-bottom: 0%;
    background-color: #e7f2f6;
  }
  .MuiTab-labelContainer-146 {
    width: 94%;
    padding: 6px 5px !important;
    box-sizing: border-box;
  }
  .customModal.visible {
    background: rgba(0, 0, 0, 0.3);
    position: fixed;
    top: -200px !important;
    left: 0;
    display: flex;
    width: 100%;
    height: 100%;
    z-index: 4;
  }
  .crop-container .buttonOutMain > div {
    font-size: 15px;
  }
}

.reserved-list {
  list-style: none;
  margin-bottom: 13px;
  padding: 4px;
  display: grid;
}

.sweet-loading {
  position: absolute;
  top: 29px;
  margin-left: 20px;
  z-index: 999999;
  margin-left: 46%;
  margin-top: 16%;
}

.complete-loading {
  /* position: absolute;*/
  z-index: 99;
  margin-left: 46%;
}

.refundPartialModal .modal-content{
  width: 600px;
  margin-left: 0 !important;
}
.refundPartialModal .jumperDetail{
  margin-left: 0;
}

.activeStaffList {
  /* position: absolute;*/
  overflow-y: scroll;
}

.report-loading {
  position: absolute;
  z-index: 999999;
  margin-left: 35%;
  margin-top: 4%;
}

.jumper-name {
  font-size: 0.8rem !important;
  padding: 0.115rem 0.15rem !important;
}

.jumper-name .fa-plus:hover {
  color: green;
}

.jumper-add-button {
  font-size: 0.7rem !important;
}

.jumper-footer {
  padding: 0.35rem 0.75rem !important;
}

/* Gear Load */
.gearModal {
  width: 93%;
  background: rgb(255, 255, 255);
  z-index: 9999;
  position: absolute;
  color: #666;
}

.inputBox input {
  width: 100%;
  margin-bottom: 10px;
  padding: 0px 5px;
}

.editGear,
.editPicker {
  margin-right: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  border: #fff;
  padding: 0px 5px;
  height: 30px;
}
/* Reservation Css */

.customHeading {
  text-align: center;
  display: inline-block;
  padding: 10px;
  background: #1c6bcf;
  color: white;
  margin-top: 10px;
  margin-bottom: 20px;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  font-size: 18px;
  font-weight: 100;
}

.customBackButton {
  text-align: center;
  display: inline-block;
  padding: 10px;
  background: #1c6bcf;
  color: white;
  margin-top: 10px;
  margin-bottom: 20px;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  font-size: 18px;
  font-weight: 100;
  cursor: pointer;
}

.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border: 0px;
}

.react-calendar button {
  border-radius: 0px;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
}

.react-calendar {
  width: 100%;
  max-width: 400px;
  line-height: 1.525em;
  margin: auto;
}

.error-validation {
  font-size: 12px;
  margin-bottom: 2px;
}

.ticketHeading {
  font-weight: normal;
  font-size: 25px;
  color: #013e8b;
}

.lightButtonBorder {
  /* border: 1px solid #c1ccd7; */
  box-shadow: #c1ccd7 0px 0px 34px 2px;
  -webkit-box-shadow: #c1ccd7 0px 0px 34px 2px;
  -moz-box-shadow: #c1ccd7 0px 0px 34px 2px;
}

.lightButtonBorder.active {
  /* border: 1px solid #c1ccd7; */
  box-shadow: #013e8b 0px 0px 34px 2px;
  -webkit-box-shadow: #013e8b 0px 0px 34px 2px;
  -moz-box-shadow: #013e8b 0px 0px 34px 2px;
}

.timeSelect {
  letter-spacing: 1px;
  border-radius: 30px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  padding-left: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
}

.roundFix {
  padding-left: 0.6rem !important;
  padding-right: 0.6rem !important;
  color: #013e8b;
  background: rgb(198, 222, 230);
}

.timeSelect.appFont.active {
  color: white;
}

button.active.timeSelect {
  background: #013e8b;
}

.active.timeSelect.active > .roundFix {
  background: #022b5f;
  color: white;
}

.fontWhite {
  color: white;
}

.fontNoColor {
  font-size: 18px;
}

.appFont {
  font-size: 18px;
  color: #013e8b;
}

.timeSelect.appFont.active {
  color: white;
}

.pointer-event-none {
  pointer-events: none;
}

hr {
  margin-top: 0px !important;
  border-top: 1px solid rgba(0, 0, 0, 0.62);
}

.downloadCSVMain {
  background: cornflowerblue;
  padding: 10px;
  text-align: end;
  color: white;
  font-size: 18px;
}

.downloadCSV {
  margin-left: 20px;
  border: 2px solid white;
  padding: 5px;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: 5px 10px 8px 2px rgba(0, 0, 0, 0.3);
}

.downloadCSV:hover {
  background: #1c6bcf;
}

.downloadCSV:active {
  color: black;
  border: 2px solid black;
}

.containerPadding {
  padding-bottom: 0px !important;
  padding-left: 50px !important;
  padding-right: 50px !important;
}

.cardOverRide {
  max-width: 400px;
  margin: auto;
  margin-top: 50px;
  background: #e7f2f6 !important;
}

.cardOverRide .card-header {
  text-align: center;
  color: rgb(8, 39, 100);
  background: transparent;
  border-bottom: 0px;
  letter-spacing: 1px;
}

.cardOverRide .table td {
  font-weight: 600;
  color: rgb(8, 39, 100);
}

.cardOverRide .table th {
  border-top: 0px;
  font-size: 14px;
  font-weight: normal;
  color: rgb(8, 39, 100);
}

.checkoutButton {
  margin: auto;
  display: block !important;
  font-size: 22px !important;
  padding: 20px !important;
  margin-top: 50px;
  margin-bottom: 30px;
  background: rgb(1, 62, 139) !important;
}

.ticketContainer {
  margin: auto !important;
  padding: 40px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.customLabel {
  margin-bottom: 0px !important;
  font-size: 12px !important;
  color: #013e8b !important;
}

.customInputField {
  border: 0px !important;
  border-bottom: 1px solid #1c6bcf !important;
  border-radius: 0px !important;
  -webkit-border-radius: 0px !important;
  -moz-border-radius: 0px !important;
  color: #1c6bcf !important;
}

.checkoutButton:disabled {
  background-color: rgb(1, 62, 139) !important;
}

/* Reservation css End */

.input-container {
  display: -ms-flexbox;
  /* IE10 */
  display: flex;
  width: 100%;
  margin-bottom: 15px;
}

.icon {
  padding: 10px;
  background: #00419d;
  color: white;
  min-width: 50px;
  text-align: center;
}

.input-field {
  width: 100%;
  padding: 10px;
  outline: none;
}

.input-field:focus {
  border: 2px solid dodgerblue;
}

/* Set a style for the submit button */

/* .btn {
  background-color: dodgerblue;
  color: white;
  padding: 15px 20px;
  border: none;
  cursor: pointer;
  width: 100%;
  opacity: 0.9;
} */

/*
.btn:hover {
  opacity: 1;
} */

.StaffSummary tr td {
  padding-left: 1px;
  padding-right: 0px;
  padding-top: 4px;
  padding-bottom: 4px;
}

.tandemGroupHeading td{
  font-weight: bold;
  font-style: italic; 
}

.StaffSummary tr {
  margin-left: 10px;
}

.StaffSummaryHr {
  border: 1px dashed white;
  margin: 0;
}
.error-modalHeader {
  background: #e85e6c;
  border-bottom: none;
  position: relative;
  text-align: center;
  border-radius: 5px 5px 0 0;
}

.error-jumperHeader {
  background: #1c6bcf;
  border-bottom: none;
  position: relative;
  text-align: center;
  border-radius: 5px 5px 0 0;
}

.error-jumperHeader h5 {
  text-align: center;
  color: #fff;
  margin-left: 40%;
}

.priceHeading {
  color: #03173f;
  font-size: 19px;
  font-weight: 500;
  font-family: Roboto;
  text-transform: capitalize;
}
.orderCol {
  position: relative;
  padding-left: 20px !important;
}
.orderField {
  width: 35px;
  text-align: left;
  padding-left: 10px;
}

.loadSummary {
  padding-left: 14px;
  padding-top: 2px;
  font-weight: bold;
}

.loadSummary p {
  margin-bottom: 0.2rem !important;
  font-size: 15px;
}

@media only screen and (max-width: 768px) {
  .Login form {
    background: #ffffff none repeat scroll 0 0;
    border-radius: 2px;
    margin: 10px auto 30px;
    /* max-width: 60% !important; */
    max-width: 90% !important;
    padding: 0px 70px 70px 71px;
  }
  .crop-container {
    width: 70%;
    height: 40%;
    left: 15%;
  }
  .crop-container .buttonOutMain {
    width: 300px;
  }
  .crop-container .buttonOutMain > div {
    padding: 0.2rem 0.4rem;
    font-size: 24px;
  }
}

.loadSummary table td {
  padding: 1px;
}

.headerDropDown {
  background: blue !important;
  border: 0px !important;
  color: white !important;
  border-bottom: 1px solid white !important;
  /* width: 100%; */
  position: absolute;
  top: -11px;
  right: 20%;
  white-space: nowrap;
  /* padding: 36px; */
  width: 86px;
  /* position: absolute; */
  /* right: -110px;
    top: -61px; */
}

.affheaderDropDown {
  background: blue !important;
  border: 0px !important;
  color: white !important;
  border-bottom: 1px solid white !important;
  /* width: 100% !important; */
  width: 54%;
  position: absolute;
  top: -11px;
  right: 15%;
  /* padding: 36px; */
  width: 86px;
}

.cardButton {
  /* background: #00419D; */
  background-image: linear-gradient(
    bottom,
    rgb(79, 142, 191) 0%,
    rgb(102, 166, 214) 100%
  );
  background-image: -o-linear-gradient(
    bottom,
    rgb(79, 142, 191) 0%,
    rgb(102, 166, 214) 100%
  );
  background-image: -moz-linear-gradient(
    bottom,
    rgb(79, 142, 191) 0%,
    rgb(102, 166, 214) 100%
  );
  background-image: -webkit-linear-gradient(
    bottom,
    rgb(79, 142, 191) 0%,
    rgb(102, 166, 214) 100%
  );
  background-image: -ms-linear-gradient(
    bottom,
    rgb(79, 142, 191) 0%,
    rgb(102, 166, 214) 100%
  );
  color: white !important;
  border-radius: 0px 0px 12px 12px;
  border-radius: 0px 0px 12px 12px;
}

.cardButton:hover {
  background-image: linear-gradient(
    bottom,
    rgb(79, 142, 191) 0%,
    rgb(102, 166, 214) 100%
  );
  background-image: -o-linear-gradient(
    bottom,
    rgb(79, 142, 191) 0%,
    rgb(102, 166, 214) 100%
  );
  background-image: -moz-linear-gradient(
    bottom,
    rgb(79, 142, 191) 0%,
    rgb(102, 166, 214) 100%
  );
  background-image: -webkit-linear-gradient(
    bottom,
    rgb(79, 142, 191) 0%,
    rgb(102, 166, 214) 100%
  );
  background-image: -ms-linear-gradient(
    bottom,
    rgb(79, 142, 191) 0%,
    rgb(102, 166, 214) 100%
  );
  background-image: -webkit-gradient(
    linear,
    left bottom,
    left top,
    color-stop(0, rgb(79, 142, 191)),
    color-stop(1, rgb(102, 166, 214))
  );
}

.rbc-btn-group {
  display: contents !important;
}

/* .rbc-time-content {
  border-top: none;
  padding-top: 0px;
} */

.rbc-time-header .rbc-row:nth-of-type(2) {
  display: none !important;
}

.rbc-header {
  padding-top: 4px;
  padding-bottom: 4px;
}

.jumpType-button {
  text-align: left;
  padding-left: 6%;
  cursor: pointer;
  border: none;
  color: black;
  background: #dcdcdc;
  font-size: 18px;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
}

.planeType-button {
  /* background-image: -webkit-gradient( linear, left bottom, left top, color-stop(0, rgb(79,142,191)), color-stop(1, rgb(102,166,214)) ); */
  border: none;
  color: black;
  background: #dcdcdc;
  font-size: 16px;
  border-radius: 30px;
  cursor: pointer;
  /* width: 100%; */
  text-align: center;
  position: relative;
  /* top: -26px; */
  width: 100px;
  /* left: 66%; */
}

.tranferCredit {
  position: absolute;
  bottom: 0px;
  z-index: 2000;
  left: 20%;
}

.borderJumps {
  border-left: solid 1px silver;
  border-right: solid 1px silver;
}

.jumptype {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  font-size: 20px;
  /* padding-left: 4% !important; */
}

.jumpClose {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  /* padding-right: 4% !important; */
}

.carousel__next-button {
  position: absolute;
  top: 50%;
  right: 0;
  margin-right: -6px !important;
}

.carousel__back-button {
  position: absolute;
  top: 50%;
  left: 0;
  margin-left: -6px !important;
}

.plane-dropdown {
  background: blue !important;
  border: 0px !important;
  color: white !important;
  border-bottom: 1px solid white !important;
  width: 54%;
  top: -11px;
  right: 20%;
  width: 100px;
}

.alert-Type {
  position: fixed !important;
  z-index: 222;
  width: 62%;
  margin-top: 54px;
}

.alert-complete {
  z-index: 222;
  width: 78%;
  /* margin-top: 54px; */
}

.alert-loadcomplete {
  z-index: 222;
  width: 62%;
  position: fixed !important;
}

.alert-panel {
  position: fixed;
  z-index: 9999;
  width: 62%;
  margin-top: 65px;
}

.bookingBack {
  margin: auto;
  display: block !important;
  font-size: 22px !important;
  padding: 20px !important;
  margin-top: 5px;
  margin-bottom: 0px;
  background: rgb(1, 62, 139) !important;
}

.ifram-bookingBack {
  margin: auto;
  display: block !important;
  /* font-size: 22px !important; */
  /* padding: 20px !important; */
  margin-top: 5px;
  margin-bottom: 0px;
  background: rgb(1, 62, 139) !important;
}

.dropdown-menu1 {
  position: static !important;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: block !important;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.refund_giftcard_modal-error {
  border-color: #d13939;
}

.dropdown-plan {
  position: absolute;
  top: 32%;
  right: 1%;
  width: 185px;
  text-align: left;
  z-index: 1000;
  display: block !important;
  padding: 0.5rem 0;
  font-size: 1rem;
  color: #212529;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.dropdown-plan .dropdown-item {
  cursor: pointer;
}

.dropdown-plan-show-config-T {
  position: absolute;
  width: 185px;
  text-align: left;
  z-index: 1000;
  display: block !important;
  padding: 0.5rem 0;
  font-size: 1rem;
  color: #212529;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.dropdown-plan-show-config-T .dropdown-item {
  cursor: pointer;
}
.accountSummary {
  margin-left: 6px;
  width: 97%;
  height: 200px;
  display: inline-block;
  overflow: auto;
}

.active-staff-btns-container button {
  font-size: 14px;
}

.jumper_modal_primary, .seat_cap_modal_primary {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.bookedSeats {
  float: left;
  /*background: #e4dcd2;*/
  /* border-radius: 50%; */
  padding: 1px;
  font-size: 13px;
}


.jumper_modal_row, .seat_cap_modal_row {
  display: flex;
  width: 90%;
  align-items: center;
  margin-bottom: 15px;
}

.fa-x-icon {
  margin-left: 0px;
}

.seat_cap_modal_row {
  justify-content: space-between;
}

.jumper_modal_name {
  width: 90%;
  margin-bottom: 15px;
}

.checkbox_weight_input {
  margin-left: 107px;
  margin-bottom: 6px;
}

.container {
  width: 5%;
  padding-left: 0px;
  display: block;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
  border: 1 px solid blue;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 15px;
  width: 15px;
  border: 1px solid #105ec1;
  border-radius: 2px;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.container input:checked ~ .checkmark:after {
  display: block;
}

.container .checkmark:after {
  left: 4px;
  width: 5px;
  height: 10px;
  border: solid black;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.jumper_modal_label {
  text-align: left;
  width: 30%;
}

.jumper_checkbox {
  font-size: 16px;
  margin-bottom: 2px;
  width: 95%;
}

.select-tandem,
.select-video {
  width: 45%;
  height: 30px;
  border-radius: 5px;
  border: 1px solid #105ec1;
  padding: 0 2px 3px 2px;
}

.label-tandem,
.label-video {
  margin-bottom: 3px;
  font-size: 16px;
  width: 65%;
}

/* .modal-body {
  color: #ffffff;
}
.modal-header {
  color: #ffffff;
} */
.input_name {
  width: 100%;
  height: 40px;
  border-radius: 5px;
  border: 1px solid #105ec1;
  padding: 12px;
}
.jumper_modal, .seat_cap_modal {
  width: 360px;
}
.jumper_modal_footer, .seat_cap_modal_footer {
  padding: 7px;
}
.jumper-add-btn, .seat_cap-add-btn {
  margin-right: 100px;
  width: 138px;
  background-color: #105ec1;
}

.fa_fa_trash {
  margin: 0px 10px;
  width: 35px;
  border: 1px solid white;
  text-align: center;
  height: 35px;
  border-radius: 50%;
  padding-top: 8px;
  background: dodgerblue;
  color: white;
  cursor: pointer;
}
.closing-modal {
  position: absolute;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  display: flex;
  background-color: #105ec1;
  color: white;
  bottom: 97%;
  margin-left: 62%;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.seat_cap_input {
  width: 50%;
  height: 40px;
  border-radius: 5px;
  border: 1px solid #105ec1;
  padding: 12px;
}

.close-x-icon {
  bottom: 95%;
  margin-left: 75%;
}
.closing-modal:hover {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
}
.fa {
  margin-left: 2px;
  font-size: 15px;
}

.timeSlotBtn {
  margin-left: 15px;
  font-size: 15px;
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.timeSlotBtn:hover {
  color: #fff;
  background-color: #218838 !important;
  border-color: #1e7e34 !important;
}

.left-container {
  padding: 10px;
  /* width: 12.666% !important; */
  width: 14.5%;
}
/* .left-container.ultra-left-menu {
  width: 10%;
} */

.middle-container {
  /* width: 66.666% !important; */
  width: 68.5%;
}
.middle-container-no-rightmenu {
  width: 85.5%;
}

.rightCol {
  /* width: 20.666% !important; */
  width: 17%;
}

/* .middle-container.ultra-middle-container{
  width: 80%;
}
.rightCol.ultra-rightCol{
  width: 10%;
} */

/* .left-container {
  padding: 10px;
} */

@media only screen and (min-device-width: 1921px) and (max-device-width: 3024px) {
  .accountSummary {
    margin-left: 6px;
    width: 97%;
    height: 400px;
    display: inline-block;
    overflow: auto;
  }
  .left-container {
    width: 10%;
  }
  .middle-container {
    width: 80%;
  }
  .rightCol {
    width: 10%;
  }
}

/* For Standard Screen 1366*768 */

@media screen and (max-width: 1366px) {
  .rightCol .columnHeading {
    background: #00419d;
    padding: 16px;
  }
  .columnBody {
    padding: 26px 5px;
    text-align: center;
  }
  .tranferCredit {
    position: absolute;
    bottom: -20px;
    z-index: 2000;
    left: 20%;
  }
}

.wrapper {
  padding: 20px;
}

@keyframes shadow-pulse {
  0% {
    box-shadow: 0 0 0 1px rgb(28, 108, 255);
  }
  100% {
    box-shadow: 0 0 0 10px rgb(73, 137, 255);
  }
}

@keyframes shadow-pulse-next {
  0% {
    box-shadow: 0 0 0 0px rgb(0, 128, 0);
  }
  100% {
    box-shadow: 0 0 0 6px rgb(0, 128, 0);
  }
}

.fe-pulse {
  animation: pulse 2s infinite;
}

.fe-nextpulse {
  animation: shadow-pulse-next 2s infinite;
  margin-top: 4px;
}

@keyframes pulse {
  from {
    transform: scale(1);
  }
  50% {
    transform: scale(0.85);
  }
  to {
    transform: scale(1);
  }
}

.editablePrice:hover {
  color: #ffffff !important;
}

@keyframes fadeIn {
  from {
    opacity: 0.5;
  }
}

.fadeingLoader {
  animation: fadeIn 1s infinite alternate;
}

/* Admin container settings,alerts etc. */

.admin-container table {
  margin: 0 auto 20px auto;
  background-color: #e7f2f6;
  width: 90%;
}

.admin-container table .main-heading {
  letter-spacing: 1px;
  color: #e7f2f6;
  background-color: #1c6bcf;
}

.customRightButton.rightButton {
  background-color: #59c5dc;
  color: white;
}

@media (min-width: 767px) {
  #modal-main.jumpers {
    max-width: 750px;
  }
}

@media (min-width: 992px) {
  #modal-main.jumpers {
    max-width: 800px;
  }
}
@media (min-width: 1920px) and (max-width: 2560px) {
  .left-container {
    padding: 10px;
    width: 10.4%;
  }

  .middle-container {
    width: 70%;
  }
  .middle-container-no-rightmenu {
    width: 89.6%;
  }
  .rightCol {
    width: 19.6%;
  }
}
@media (min-width: 2560px) and (max-width: 3440px) {
  .left-container {
    padding: 10px;
    width: 8%;
  }

  .middle-container {
    width: 80%;
  }
  .middle-container-no-rightmenu {
    width: 92%;
  }

  .rightCol {
    width: 12%;
  }
  .loadSummary {
    font-size: 13px;
  }
  .loadSummary p {
    font-size: 13px;
  }
  .tabContentCon h2 {
    font-size: 1.5rem;
  }
}
