.oauth_Settings_main {
    background-size: cover;
    background-image: url("../../images/background.svg");
    display: flex;
}

.left_container {
    width: 14.5%;
}
  
.dizio_logo img {
    height: 100%;
    margin: "auto";
    display: "block";
}

.right_container {
    width: 85.5%;
    height: 100vh;
}

.dizio_logo {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
}

.oauth_redirect_right_container_bottom {
    height: calc(100% - 50px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.oauth_redirect_right_container_bottom p{
    color: #ffff;
    font-weight: 800;
    font-size: 20px;
}