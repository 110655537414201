.navigation-container {
  position: absolute;
  display: flex;
  justify-content: space-around;
  background-color: white;
  width: 30px;
  height: 23px;
  top: 1px;
  z-index: 1;
}

.navigation-container > .hamburger {
  margin: 1px;
  margin-top: 3px;
  width: 28px;
  height: 17px;
}

.navigation-container .setting-btn {
  border: 0;
  font-size: 22px;
  line-height: 0;
  background: white;
}

.navigation-container .small-modal {
  position: absolute;
  background-color: white;
  color: black;
  width: 150px;
  min-height: 155px;
  height: 20vh;
  /*  height of navigation*/
  top: 24px;
  right: 0px;
  padding: 2px 4px;
  border-radius: 2px;
  box-shadow: 3px 1px 5px #5e5e5e;
  text-align: center;
  display: none;
}

.navigation-container .small-modal.active {
  display: block;
}

.navigation-container .small-modal .link-menu {
  list-style: none;
  padding: 0;
}

.navigation-container .small-modal .link-menu a {
  color: black;
  font-weight: 600;
}

@media (min-width: 576px) {
  .navigation-container .small-modal {
    width: 215px;
  }
  .navigation-container .small-modal .link-menu a {
    font-size: 1.2rem;
  }
}

/* Toggle Switch */
.setting-switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.setting-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.settings-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.settings-slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .settings-slider {
  background-color: #2196f3;
}

input:focus + .settings-slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .settings-slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded settings-sliders */
.settings-slider.round {
  border-radius: 34px;
}

.settings-slider.round:before {
  border-radius: 50%;
}
