.terms_conditions_main {
  background-size: cover;
  background-image: url("../../images/background.svg");
  display: flex;
}

.left_container {
  width: 14.5%;
}

.dizio_logo img {
  height: 100%;
  margin: "auto";
  display: "block";
}

.right_container {
  width: 85.5%;
  height: 100vh;
}

.dizio_logo {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
}
.terms_conditions_right_container_bottom {
  height: calc(100% - 50px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.add_terms_conditions_main {
  width: 100%;
  display: flex;
  justify-content: center;
  height: 60px;
}

.add_terms_conditions_secondary {
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  border: 2px solid darkgrey;
  overflow: hidden;
}

.text_area_div {
  width: 80%;
  height: 100%;
}

.text_area_div input {
  width: 100%;
  height: 100%;
  border: 2px solid transparent;
  outline: none;
  padding: 10px;
  background: #e7f2f6;
  box-sizing: border-box;
}

.error {
  border: 2px solid red !important;
}

.add_update_button_div {
  width: 20%;
  height: 100%;
  overflow: hidden;
}

.add_update_button_div button {
  width: 100%;
  height: 100%;
  border: none;
  background: #1c6bcf;
  color: #e7f2f6;
  font-size: 1.4em;
  font-family: initial;
}

.terms_conditions_table_main {
  width: 100%;
  height: calc(100% - 250px);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.terms_conditions_table {
  width: 80%;
  height: 90%;
  position: relative;
}

.loader_div {
  position: absolute;
}

.terms_conditions_heading {
  background-color: #1c6bcf;
  color: #e7f2f6;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 60px;
  /* border-bottom: none !important; */
  border: 2px solid darkgray;
  box-sizing: border-box;
}

.terms_conditions_heading tr {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-right: 10px;
  padding-left: 20px;
}

.terms_conditions_heading th {
  border: none !important;
}

.add_conditions_main {
  display: flex;
  justify-content: end;
}

.add_conditions_secondary {
  font-size: 0.7rem !important;
  background: #1c6bcf !important;
  color: white;
  border: 2px solid white !important;
  width: 25px;
  height: 25px;
  border-radius: 50% !important;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 8px !important;
  padding: 0.35rem 0.55rem;
}

.terms_conditions_body {
  background-color: #e7f2f6;
  width: 100%;
  color: black;
  overflow: scroll;
  position: absolute;
  height: calc(100% - 60px);
  border-top: none !important;
  border: 2px solid darkgray;
  box-sizing: border-box;
}

.body_row {
  border-bottom: 1px solid #1c6bcf;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.body_row td:nth-child(1) {
  width: 5%;
  padding-left: 20px;
  border-top: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.body_row td:nth-child(2) {
  width: 80%;
  padding-left: 0px;
  border-top: none;
}

.body_row td:nth-child(3) {
  width: 15%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: none;
}

.body_row td:nth-child(3) button {
  width: 25%;
  display: flex;
  border: 1px solid transparent;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  height: 30px;
  background: center;
  outline: none;
  margin-right: 10px;
}

.body_row td:nth-child(3) button:hover {
  background-color: floralwhite;
}

.warning_ques {
  display: flex;
  justify-content: center;
}

.warning-modal-col {
  display: flex;
  justify-content: center;
}

.warning-modal-col button {
  width: 20%;
  border: none;
  background: white;
  border-radius: 5px;
  height: 100%;
  margin-right: 15px;
}

