.Awrapper {
  z-index: 1000;
  border: #000 solid 2px;
  position: absolute;
  background: #fff;
  margin: 0 auto;
  width: 900px;
  visibility: hidden;
  top: 0;
  left: 0;
  right: 0;
  display: block;
  font-family: "Roboto", sans-serif !important;
}
.price {
  background: #b7b7b7;
}
.Atopbar {
  background: #fff;
  border: solid 2px #000;
  padding: 15px;
  float: left;
  width: 100%;
  text-align: center;
  box-sizing: border-box;
}

.Atopbar h3 {
  color: #000;
  margin: 0 auto;
  display: block;
  text-align: center;
  text-transform: uppercase;
}

.Atopbar h3 span {
  background: #fff;
  border-radius: 20px;
  color: #000;
  text-transform: lowercase;
  font-size: 26px;
}
.Atopbar span {
  text-align: center;
}

.Atopbar p {
  margin: 16px auto 0;
  font-size: 16px;
  float: left;
  color: #000;
  padding: 5px 10px;
  border-radius: 3px;
}

.Atopbar img {
  float: right;
  width: 22px;
  margin-top: -20px;
}

.Alist {
  padding: 15px;
  float: left;
  width: 100%;
  box-sizing: border-box;
  margin: 10px auto;
  border-radius: 5px;
  border: #000 solid 2px;
  background-color: #fff;
}

.Alist label {
  background: #dcdcdc;
  padding: 5px 9px;
  border-radius: 23px;
  float: left;
  font-size: 15px;
  margin-top: 6px;
}

.Alist span {
  display: inline-block;
  margin: 0px auto;
  margin-left: 12px;
  padding: 8px;
  /* background: #1c6bcf; */
  color: #000;
  border: 3px solid #000;
  border-radius: 3px;
}

.Ablock {
  float: left;
  padding: 0px 0px;
  border-left: 2px solid #ddd;
  margin-left: 15px;
  /* width: 650px;  */
}

.Amain-block {
  float: left;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
}
