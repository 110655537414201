.iframe-container {
  position: absolute;
  z-index: 9999;
  width: 94%;
  height: 94%;
  top: 3%;
  left: 3%;
  background: white;
  padding: 40px 5px 5px 5px;
  border-radius: 13px;
  box-shadow: 1px 2px black;
}

.iframe-container button {
  position: absolute;
  top: 5px;
  right: 10px;
  padding: 1px 2px 2px 2px;
}

.iframe-container iframe {
  width: 100%;
  height: 100%;
}
