.page-sidebar .sidebar-menu {
  list-style: none;
  margin: 0px;
}

.page-sidebar .sidebar .logout-btn {
  /* padding-left: 90px; */
  cursor: default;
}

/* SEARCH FIELDS */

.page-sidebar .search-fields {
  margin-top: 20px;
  font-size: 14px;
}

.page-sidebar .search-fields > div > div:first-of-type {
  border: 1px solid #eff0f1;
  padding: 0px 0px 0px 5px;
  border-radius: 50px;
  background-color: #f6f7fb;
}

.page-sidebar .sidebar-menu .sidebar-header {
  font-size: 15px;
  letter-spacing: 0.5px;
  padding-bottom: 8px;
  padding-top: 8px;
  cursor: default;
  text-transform: capitalize;
  font-weight: 600;
  color: rgba(255, 255, 255, 0.85);
  font-family: Nunito;
}

.page-sidebar .sidebar-menu > li > a {
  display: block;
  transition: 0.3s;
}

.page-sidebar .sidebar-menu a {
  text-decoration: none;
}

@keyframes sidebar-menu-open {
  0% {
    transform: translate(0px, -50%) scale(1, 0);
  }
  100% {
    transform: translate(0px, 0%) scale(1, 1);
  }
}

.page-sidebar .sidebar-menu .sidebar-header svg {
  width: 14px;
  height: 14px;
  margin-right: 14px;
  stroke-width: 3px;
  vertical-align: sub;
}

.page-sidebar .sidebar-menu .sidebar-submenu > li > a,
.page-sidebar .sidebar-menu .sidebar-submenu .func-tag {
  padding-bottom: 7px;
  padding-top: 7px;
  font-size: 15px;
  transition: 0.3s;
  text-transform: capitalize;
  position: relative;
  padding-left: 10px;
  line-height: 2.5;
  letter-spacing: 0.7px;
  font-family: Nunito;
  color: rgba(255, 255, 255, 0.85);
}

.page-sidebar .sidebar-menu .sidebar-submenu .func-tag {
  /* changes over */
  cursor: pointer;
  margin: 0;
  padding-bottom: 2px;
  padding-top: 4px;
}

.page-sidebar .sidebar-menu .sidebar-submenu {
  display: none;
  animation: sidebar-menu-open 0.3s alternate forwards;
  list-style: none;
}

.page-sidebar .sidebar-menu li.active > .sidebar-submenu {
  display: block;
  margin-left: 15px;
}

.page-sidebar .sidebar-menu .down-arrow,
.page-sidebar .sidebar-menu .right-arrow {
  font-size: 15px;
  margin-top: 0.3rem;
}

.page-sidebar .sidebar-menu .down-arrow {
  transform: rotate(90deg);
}

.page-sidebar .sidebar-menu .sidebar-submenu > li > a > .fa-circle,
.page-sidebar .sidebar-submenu .func-tag .fa-circle {
  width: 12px;
  font-size: 4px;
  position: absolute;
  left: 0;
  top: 15px;
}

.page-sidebar .sidebar-submenu .func-tag .fa-circle {
  top: 23px;
}

/* CREATE LOAD BUTTON */

.create-load-btn {
  position: relative;
}

.create-load-btn button {
  font-size: 14px !important;
  padding: 5px 0 !important;
  border: none;
}

.create-load-btn .menu-option {
  top: -100%;
  left: 100%;
  color: black;
  /* as carouse arrow has z-index: 1000; */
  z-index: 2000;
}

.create-load-btn .customRightButton {
  margin: 20px 0;
}

/* for small screens */

.page-sidebar .sidebar {
  padding: 0 10px;
}

@media (min-width: 576px) {
  /* For screen greater than 576px */
  .page-sidebar .search-fields > div > div:first-of-type {
    padding: 5px 5px 5px 10px;
  }
  .page-sidebar .sidebar-menu .sidebar-header {
    font-size: 18px;
  }
  .page-sidebar .sidebar-menu .sidebar-submenu > li > a,
  .page-sidebar .sidebar-menu .sidebar-submenu .func-tag {
    font-size: 17px;
  }
  .page-sidebar .sidebar {
    padding: 0;
  }
  .create-load-btn button {
    font-size: 16px !important;
    padding: 11px !important;
  }
}
.paperWaiverCheck {
  display: flex;
  align-items: center;
}
