@import "bootstrap/dist/css/bootstrap.css";
@import "font-awesome/css/font-awesome.min.css";
body {
  margin: 0;
  padding: 0;
  font-family: "Exo 2", sans-serif !important;
}

#summaryPanel th {
  font-size: 13px !important;
  font-weight: 400;
}

#summaryPanel td {
  font-size: 13px !important;
}

.jumper-name .fa-plus:hover {
  color: green !important;
}

.isBlackedOut {
  background-color: rgba(128, 128, 128, 0.64) !important;
  opacity: 1;
  border: 0px;
  font-size: 13px !important;
  display: block;
}

.ReservedDates {
  background-color: green !important;
  opacity: 1;
  border: 0px;
  font-size: 13px !important;

  display: block;
}

.isFull {
  background-color: red !important;
  opacity: 1;
  border: 0px;
  font-size: 13px !important;

  display: block;
}

.noBlackout {
  background-color: #4285f4 !important;
  opacity: 1;
  border: 0px;
  font-size: 13px !important;
  display: block;
}

tr.expandable > td {
  box-shadow: inset 0 3px 6px -3px rgba(0, 0, 0, 0.2);
  padding: 0;
}

tr.expandable > td > .inner {
  margin: 15px;
  overflow: hidden;
}

.styleSendEmail {
  overflow-y: scroll;
  max-height: 140px;
}
.styleSendEmail::-webkit-scrollbar {
  display: none;
}
.styleSendEmail td {
  color: #4e5363;
}
.styleSendEmail tr {
  margin-bottom: 0.5rem;
  display: inline-block;
  width: 100% !important;
}
.styleSendEmail.modal-body {
  padding-bottom: 0rem;
}
