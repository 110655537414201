@media all and (min-width: 480px) {
  .Login {
    padding: 190px 0;
  }

  .Login form {
    margin: 0 auto;
    max-width: 320px;
  }
}

.label-color {
  color: #fff;
}

.Login form {
  background: #ffffff none repeat scroll 0 0;
  border-radius: 2px;
  margin: 10px auto 30px;
  max-width: 38%;
  padding: 0px 70px 70px 71px;
}
