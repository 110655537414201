/* For Wide and Ultra Wide screen please use the media queries written the most last as they are for accurate size */
div[id^="innercard"] {
  height: calc(100vh - 265px);
}
div[id^="innercard0"] {
  height: calc(100vh - 155px);
}
div[id^="school-innercard"] {
  height: calc(100vh - 375px);
}
div[id^="complete-innercard"] {
  height: calc(100% - 70px);
}

.btn-overflow {
  overflow-y: clip;
  overflow-x: visible;
}
_::-webkit-full-page-media,
_:future,
:root .btn-overflow {
  overflow-x: hidden;
  overflow-y: hidden;
}
.single-jump-card {
  display: flex;
}
.single-jump-card__first_div {
  width: 99%;
}
.dotToggle li {
  text-transform: capitalize;
  padding: 0.5rem 0.25rem;
  text-align: center;
}
.dotToggle .dropDownMenu {
  padding: 0;
}
.editable-timer {
  color: white;
  text-align: center;
  font-weight: 600;
  font-size: 1rem;
  position: relative;
  top: 5px;
}

.load-to-show-inline {
  display: flex;
  justify-content: center;
  align-items: center;
}

.load-to-show-inline1 {
  display: flex;
  justify-content: center;
}

.editable-timer .edit-btns {
  padding: 0 6px;
  margin: 0 2px;
  padding-bottom: 2px;
  font-size: 1rem;
  line-height: 1;
}

.load-header {
  height: 100px;
}

.load-print {
  cursor: pointer;
  right: 4px;
  position: absolute;
  color: white;
  top: 4px;
}

.arrow-left-btn,
.arrow-right-btn {
  background-color: #1c6bcf !important;
  z-index: 999;
  padding: 5px;
}

.arrow-left-btn img,
.arrow-right-btn img {
  height: 20px;
  margin: auto;
  display: block;
}

.arrow-right-btn {
  transform: rotate(180deg);
}

.bottom-btns {
  text-align: center;
  margin-top: 5px;
}

.bottom-btns > button {
  background: #2196f3;
  border-radius: 100px;
  margin-bottom: 4px;
  /* font-size: 1.15em; */
}
.bottom-btns button {
  font-size: 1.15em;
}

.history-plane {
  position: absolute;
  height: 30px;
  width: 79px;
  left: 18.71%;
  /* right: 92.76%; */
  top: 4.59%;
  background-repeat: no-repeat;
  bottom: 95.28%;
  background-image: url("../images/plane.png");
}

.skydiver {
  background-image: url("../images/jumper.png");
  position: absolute;
  left: 26.68%;
  right: 39.71%;
  /* top: 8.06%; */
  bottom: 11.28%;
  /* background: #FFFFFF; */
  height: 18px;
  width: 31px;
}

.remove-padding {
  display: flex;
  padding: 0rem 0rem 0rem 1.4rem;
}

.add-jumper-btn {
  position: absolute;
  top: -5%;
  left: -2%;
}

.funjumptypemoreload {
  text-align: center;
  padding: 0px;
  font-weight: bold;
  font-size: 1em;
}

.funjumptype {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  flex: 0 0 25%;
  max-width: 24% !important;
  /* padding: 0.6rem 0rem 0.6rem 1.4rem !important; */
  padding: 0rem 0rem 0rem 1.4rem;
  /* background: #f5f5f5; */
  font-weight: bold;
  font-size: 1em;
}
.funjumptype.removewidth {
  flex: 0 0 33%;
  max-width: 100% !important;
  font-weight: bold;
  /* padding: 0rem 0rem 0rem 1rem; */
}
.funjumpClass.removewidth {
  flex: 0 0 32%;
  max-width: 100% !important;
  font-weight: bold;
}

.funjumpType-button {
  cursor: pointer;
  /* margin-left: 5px; */
  font-size: "18px !important";
}

.funjumpType-button:hover {
  background: rgb(245, 236, 236);
}

.jump-card {
  padding: "0px !important";
}

.card-body {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 0rem !important;
}

.cardmiddle {
  padding-right: 6px !important;
  padding-left: 3px !important;
  margin: auto 0;
}

.tandem-load {
  margin: 0;
  /* padding: 6px;
  font-size: 21px; */
  /* width: 91px;
  height: 58px; */
  background: #ffffff;
  list-style: none;
  /* padding-top: 22px; */
  /* margin-top: 4%; */
}

.tandem-load li {
  padding: 3px;
  width: 23%;
  cursor: pointer;
  background-color: #011893;
  display: inline-block;
  color: white;
  text-align: center;
  margin-right: 3px;
  margin-top: auto;
  margin-bottom: 2px;
  vertical-align: top;
}

.jumper-load {
  display: inline-block;
  margin-right: 3px;
  background-color: rgb(1, 24, 147);
  /* font-size: 15px; */
  font-size: 1em;
  padding: 3px;
  /* border-radius: 5px; */
  margin-bottom: 1px;
  width: 23%;
  color: white;
  text-align: center;
}

.jumper-load li {
  list-style: none;
  cursor: pointer;
}

.jumper-load-pending {
  display: inline-block;
  margin-right: 3px;
  background-color: rgb(250, 128, 114);
  font-size: 1em;
  padding: 3px;
  /* border-radius: 5px; */
  margin-bottom: 1px;
  width: 23%;
  color: white;
  text-align: center;
}

.jumper-load-pending li {
  list-style: none;
  cursor: pointer;
}

.coach-load {
  margin: 0;
  /* display: -ms-inline-flexbox;
  display: inline-flex; */
  background: #ffffff;
  list-style: none;
  /* display: -ms-flexbox; */
  /* display: flex; */
}

.coach-load li {
  padding: 3px;
  width: 23%;
  cursor: pointer;
  margin-right: 3px;
  background-color: #011893;
  color: white;
  text-align: center;
  margin-top: auto;
  margin-bottom: 2px;
  display: inline-block;
}

.aff-load {
  background: #ffffff;
  /* display: -ms-flexbox;
  display: flex; */
  /* font-size: 15px; */
  /* padding: 3px; */
  /* margin-right: 5px; */
  border-radius: 5px;
  margin-bottom: 1px;
  list-style: none;
}

.aff-load li {
  /* padding: 3px; */
  /* width: 100px !important; */
  padding: 3px;
  width: 23%;
  cursor: pointer;
  display: inline-block;
  margin-right: 3px;
  background-color: #011893;
  color: white;
  text-align: center;
  margin-top: auto;
  margin-bottom: 2px;
  vertical-align: top;
}

.school-waitlist .aff-load li,
.school-waitlist .coach-load li,
.school-waitlist .jumper-load,
.school-waitlist .tandem-load li {
  /* show 3 column in school-watilist*/
  width: 31%;
}

.school-waitlist .funjumptype {
  flex: 0 0 33%;
  max-width: 33% !important;
}

.school-waitlist .cardmiddle {
  padding-right: 12px !important;
}

.school-waitlist .right-menu {
  top: 20%;
}

.columngroup-1 .aff-load li,
.columngroup-1 .coach-load li,
.columngroup-1 .jumper-load,
.columngroup-1 .jumper-load-pending,
.columngroup-1 .tandem-load li {
  width: 94%;
}

.columngroup-2 .aff-load li,
.columngroup-2 .coach-load li,
.columngroup-2 .jumper-load,
.columngroup-2 .jumper-load-pending,
.columngroup-2 .tandem-load li {
  width: 46%;
}

.columngroup-3 .aff-load li,
.columngroup-3 .coach-load li,
.columngroup-3 .jumper-load,
.columngroup-3 .jumper-load-pending,
.columngroup-3 .tandem-load li {
  width: 31%;
  vertical-align: top;
}

.columngroup-3 .school-waitlist .aff-load li,
.columngroup-3 .school-waitlist .coach-load li,
.columngroup-3 .school-waitlist .jumper-load,
.columngroup-3 .jumper-load-pending,
.columngroup-3 .school-waitlist .tandem-load li {
  /* show 2 column instead of 3 */
  width: 23% !important;
}

.editable-Jumper {
  /* position: absolute; */
  /* margin-top: 72px; */
  /* left: 73px; */
  left: 0px;
}

.btn-manifest {
  width: 140px;
  height: 43px;
  background: #000093;
  border-radius: 4px;
  color: #ffffff;
  border-radius: 6px;
  margin-left: 11px;
}

.btn-manifest:hover {
  color: white;
}

.add-btn-icon {
  font-size: 12px;
}

.manifest-cancel {
  width: 140px;
  height: 43px;
  background: #ffffff;
  border: 1px solid #757575 !important;
  box-sizing: border-box;
  border-radius: 4px !important;
  margin-left: 10px;
  color: #000093;
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #000093;
  border-color: #000093;
}

.jump-create {
  margin-left: 3px;
  /* font-size: 10px !important; */
  font-size: 0.7em !important;
  /* width: 122px; */
  border-radius: 100px;
  padding: 5px !important;
  color: black;
  display: inline-block;
  cursor: pointer;
  margin-bottom: 2px;
  background-color: white;
}

.jump-create.select-field > button {
  background-color: white;
  color: black;
  border: none;
  padding: 0 4px;
  font-size: 1em;
  font-weight: 600;
}

.jump-create:hover {
  background-color: rgb(231, 226, 226);
}

.right-menu {
  position: absolute;
  right: 16px;
  top: 4px;
  font-size: 20px;
  cursor: pointer;
}

.right-menu-morethan4 {
  position: absolute;
  right: 0px;
  top: 40px;
  font-size: 20px;
  cursor: pointer;
}

.menu-option {
  position: absolute;
  top: 13%;
  right: 10%;
  width: 210px;
  /* width: 155px; */
  background: #fff;
  list-style: none;
  z-index: 99;
}

.adjust-width-7 {
  width: 14.25%;
}

.adjust-width-8 {
  width: 12.5%;
}
.adjust-width-9 {
  width: 11.1%;
}
.adjust-width-10 {
  width: 10%;
}

.adjust-width-11 {
  width: 9%;
}

.adjust-width-12 {
  width: 8.3%;
}

.adjust-width-7 .load-header .left-side {
  font-size: 9px;
}

.menu-option-moreloads {
  flex-wrap: nowrap;
  position: absolute;
  top: 30%;
  right: 0%;
  width: 210px;
  background: #fff;
  list-style: none;
  z-index: 99;
}

.menu-option li {
  padding: 10px;
  cursor: pointer;
  border-bottom: solid 1px silver;
}

.menu-option-moreloads li {
  padding: 10px 2px;
  cursor: pointer;
  border-bottom: solid 1px silver;
}

/* .menu-option li :hover{
    padding:10px;
    border: 3px solid #0B9DDB;
  }
 */

.fun-create > .btn-primary {
  background: #ffffff;
  border: 1px solid #757575;
  box-sizing: border-box;
  border-radius: 4px;
  color: #757575;
}

.fun-create > .btn-primary :hover {
  background: #ffffff;
  /* border: 1px solid #757575; */
  box-sizing: border-box;
  border-radius: 4px;
  color: #757575;
}

.fun-create > .active {
  /* background: #FFFFFF; */
  /* border: 1px solid #757575; */
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 4px;
  color: #fff;
  background: none !important;
  background: #000093 !important;
  border-radius: 4px;
}

.fun-create > .active :hover {
  /* background: #FFFFFF; */
  /* border: 1px solid #757575; */
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 4px;
  color: #fff !important;
}

.fun-create > .btn-primary:hover {
  color: #757575;
  /* background-color: #0069d9; */
  /* border-color: #0062cc; */
}

.right-header-menu {
  position: absolute;
  right: 4.76%;
  top: 1.59%;
  font-size: 24px;
  color: #fff;
  cursor: pointer;
}

.funjumpcard.outer {
  position: absolute;
  width: 100%;
  z-index: 99;
  top: 21%;
  right: 0;
}
.isJumpOpen {
  display: none;
}
/* .team-jump-btns {
  font-weight: 170px;
  margin-left: 4px;
  margin-bottom: 4px;
  font-size: 1em;
  padding: 1px;
} */

.menu-header-option {
  position: absolute;
  top: 3%;
  /* right: 15%; */
  width: 210px;
  background: #fff;
  list-style: none;
  z-index: 99999;
}
.menu-header-option.left {
  left: 0;
}
.menu-header-option.right {
  right: 15%;
}

.menu-header-option li {
  padding: 10px;
  cursor: pointer;
  border-bottom: solid 1px silver;
}
.reducePadding {
  padding: 0.25rem 0.5rem;
  font-size: 1em;
}
.aff-menu {
  overflow: auto !important;
  max-height: 300px;
  left: calc(100% - 100px) !important;
  right: 0 !important;
  top: auto !important;
  bottom: calc(100% + 10px) !important;
  padding: 4px !important;
  transform: none !important;
}
.iad-menu {
  overflow: auto !important;
  max-height: 300px;
  left: calc(100% - 100px) !important;
  right: 0 !important;
  top: auto !important;
  bottom: calc(100% + 10px) !important;
  padding: 4px !important;
  transform: none !important;
}

.gear-up-complete {
  position: absolute;
  left: 31.39%;
  top: 3.61%;
  font-family: "Exo 2";
  font-style: normal;
  font-weight: 900;
  font-size: 27px;
  line-height: 58px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  color: #18191d;
}

.in-out-transition {
  transition: bottom linear 0.15s;
  overflow: hidden;
  position: absolute;
  bottom: -270px;
  background: rgb(24, 25, 29);
  left: 0;
}
.in-out-transition-out {
  position: absolute;
  transition: bottom linear 0.15s;
  bottom: 50px;
  background: rgb(24, 25, 29);
  left: 0;
  width: 100%;
  bottom: 29px;
  padding-top: 4px;
  padding-bottom: 10px;
}
.load-status-transition {
  max-height: 0;
  transition: max-height 0.15s ease-out;
  overflow: hidden;
}
.load-status-transition-transition {
  margin: 10px 0;
  max-height: 800px;
  transition: max-height 0.2s ease-in-out;
}

.justify-middle {
  display: flex;
  justify-content: center;
}

@media (min-width: 576px) {
  .arrow-left-btn,
  .arrow-right-btn {
    padding: 10px;
  }
  .arrow-left-btn img,
  .arrow-right-btn img {
    height: 24px;
  }
  .editable-timer {
    font-size: 1.3rem;
  }
  .editable-timer .edit-btns {
    font-size: 1.5rem;
  }
}

.padd-middle-container {
  padding: 0 10px;
}
.regular-padd-middle-container {
  padding: 0 32px;
}
.padd-middle-container-till-4-5 {
  padding: 0 18px;
}
.fun-card-footer {
  padding: 0.1rem 1.25rem;
  border-top-width: 0;
  background-color: white;
}
.fun-card-header {
  padding: 0.1rem 1.25rem;
  border-bottom-width: 0;
  background-color: white;
}

@media only screen and (min-device-width: 1920px) and (max-device-width: 2199px) {
  #setting-modal {
    /* width: 80% !important; */
  }
  div[id^="innercard"] {
    /* height: calc(100vh - 205px); */
  }

  .adjust-width-7 .load-header .left-side {
    font-size: 10px !important;
  }

  .funjumptype {
    max-width: 18% !important;
  }
  #select-txt-morethan3 {
    display: flex;
    justify-content: space-around;
  }
  .jump-create {
    /* font-size: 13px !important; */
    font-size: 0.9em !important;
    font-weight: bold;
    padding: 5px !important;
    /* height: 45px; */
    /* background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAPAAAAAyCAYAAABidVYtAAAACXBIW…KvcNe/QMCKfbXOug5Y0oi/p5cv6RoNeP8UrdH+/c7VT+vsL7rkw/Bh1UkiAAAAAElFTkSuQmCC); */
  }

  .adjust-width-7 .load-header .left-side {
    font-size: 12px !important;
  }

  .right-menu {
    position: absolute;
    right: 16px;
    top: 4px;
    font-size: 20px;
    cursor: pointer;
  }
  .right-menu-morethan4 {
    position: absolute;
    right: 0px;
    top: 25px;
    font-size: 20px;
    cursor: pointer;
  }
  .menu-option-moreloads {
    position: absolute;
    top: 13%;
    right: 10%;
    width: 80%;
    background: #fff;
    list-style: none;
    z-index: 99;
  }

  .menu-option-moreloads li {
    padding: 10px;
    cursor: pointer;
    border-bottom: solid 1px silver;
  }
  .historyheader-left {
    position: absolute;
    left: 2.6%;
    right: 92.34%;
    bottom: 68.4%;
    top: 2%;
    background-image: url("../images/blackHeader.png");
    /* background: #FFFFFF; */
    width: 131px;
    height: 99px;
  }
  .historyheader-right {
    position: absolute;
    right: 2.61%;
    top: 1.99%;
    bottom: 80%;
    background-image: url("../images/blueheader.png");
    transform: rotate(360deg);
    width: 131px;
    height: 98px;
    z-index: 99999;
  }
  .editable-FunJumper {
    padding: 0 !important;
    border: none !important;
  }
  .editable-Jumper {
    padding: 0 !important;
    border: none !important;
  }
  .gear-up-complete {
    font-size: 41px;
    left: 29.39%;
    top: 4.61%;
  }

  .load-header {
    height: 70px;
  }
  .load-header.head-3 {
    height: 100px;
  }

  .corosal-btn-padding {
    padding: 5px;
  }
  .corosal-btn-padding-till-4-5 {
    padding: 5px;
  }

  .padd-middle-container-till-4-5 {
  }
  .corosal-btn-padding-till-4-5 img {
    height: 14px;
  }
  .corosal-btn-padding img {
    height: 20px;
  }
  .funjumptype.removewidth {
    flex: 0 0 33%;
    max-width: 100% !important;
    font-weight: bold;
    padding: 0rem 0rem 0rem 1rem;
  }
}
@media only screen and (min-device-width: 2200px) and (max-device-width: 2599px) {
  .funjumptype {
    max-width: 18% !important;
  }
  .jump-create {
    font-size: 13px !important;
    font-weight: bold;
    border-radius: 100px;
    padding: 5px !important;
    /* height: 45px; */
    /* background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAPAAAAAyCAYAAABidVYtAAAACXBIW…KvcNe/QMCKfbXOug5Y0oi/p5cv6RoNeP8UrdH+/c7VT+vsL7rkw/Bh1UkiAAAAAElFTkSuQmCC); */
  }
  .right-menu {
    position: absolute;
    right: 16px;
    top: 4px;
    font-size: 20px;
    cursor: pointer;
  }

  /* .right-menu-morethan4 {
    position: absolute;
    right: 16px;
    top: 4px;
    font-size: 20px;
    cursor: pointer;
  } */

  .historyheader-left {
    position: absolute;
    left: 2.6%;
    right: 92.34%;
    bottom: 68.4%;
    top: 2%;
    background-image: url("../images/blackHeader.png");
    /* background: #FFFFFF; */
    width: 131px;
    height: 99px;
  }
  .historyheader-right {
    position: absolute;
    right: 2.61%;
    top: 1.99%;
    bottom: 80%;
    background-image: url("../images/blueheader.png");
    transform: rotate(360deg);
    width: 131px;
    height: 98px;
    z-index: 99999;
  }
  .editable-FunJumper {
    padding: 0 !important;
    border: none !important;
  }
  .editable-Jumper {
    padding: 0 !important;
    border: none !important;
  }
  .gear-up-complete {
    font-size: 41px;
    left: 29.39%;
    top: 4.61%;
  }
}

@media only screen and (min-device-width: 2600px) and (max-device-width: 3024px) {
  .funjumptype {
    max-width: 18% !important;
  }
  .jump-create {
    font-size: 14px !important;
    font-weight: bold;
    border-radius: 100px;
    padding: 5px !important;
    /* height: 45px; */
    /* background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAPAAAAAyCAYAAABidVYtAAAACXBIW…KvcNe/QMCKfbXOug5Y0oi/p5cv6RoNeP8UrdH+/c7VT+vsL7rkw/Bh1UkiAAAAAElFTkSuQmCC); */
  }
  .right-menu {
    position: absolute;
    right: 16px;
    top: 4px;
    font-size: 20px;
    cursor: pointer;
  }
  .right-menu-morethan4 {
    position: absolute;
    right: 16px;
    top: 4px;
    font-size: 20px;
    cursor: pointer;
  }
  .menu-option {
    position: absolute;
    top: 13%;
    right: 10%;
    width: 210px;
    background: #fff;
    list-style: none;
    z-index: 99;
  }
  .historyheader-left {
    position: absolute;
    left: 2.6%;
    right: 92.34%;
    bottom: 68.4%;
    top: 2%;
    background-image: url("../images/blackHeader.png");
    /* background: #FFFFFF; */
    width: 131px;
    height: 99px;
  }
  .historyheader-right {
    position: absolute;
    right: 2.61%;
    top: 1.99%;
    bottom: 80%;
    background-image: url("../images/blueheader.png");
    transform: rotate(360deg);
    width: 131px;
    height: 98px;
    z-index: 99999;
  }
  .editable-FunJumper {
    padding: 0 !important;
    border: none !important;
  }
  .editable-Jumper {
    padding: 0 !important;
    border: none !important;
  }
  .gear-up-complete {
    font-size: 41px;
    left: 29.39%;
    top: 4.61%;
  }
}

.tabContentCon {
  height: calc(100vh - 190px);
  overflow: auto;
  margin-bottom: 25px;
  padding: 0px 8px;
}

.tabContentCon .loadSummary {
  padding: 0;
}

.tabContentCon .loadSummary .without-burger {
  padding-left: 7.55%;
}

.tabsCon {
  padding: 20px 5px 10px 5px;
}

.tabsCon ul {
  margin: 0;
  text-align: center;
  display: flex;
  cursor: pointer;
}

.tabsCon,
.tabsCon ul {
  overflow: auto;
}

.tabsCon ul li {
  list-style-type: none;
  cursor: pointer;
  font-weight: 500;
  display: flex;
  align-items: center;
  background: #1f68c4;
  padding: 10px 4px;
  text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0px 6px 0px #2b638f, 0px 3px 15px rgba(0, 0, 0, 0.4),
    inset 0px 1px 0px rgba(255, 255, 255, 0.3),
    inset 0px 0px 3px rgba(255, 255, 255, 0.5);
  box-shadow: 0px 6px 0px #2b638f, 0px 3px 15px rgba(0, 0, 0, 0.4),
    inset 0px 1px 0px rgba(255, 255, 255, 0.3),
    inset 0px 0px 3px rgba(255, 255, 255, 0.5);
}

.tabsCon ul li.active {
  background-color: #0a2c56;
  box-shadow: none;
}

.tabsCon .with-badge {
  position: relative;
}

.tabsCon .with-badge span {
  /* small icon badge */
  position: absolute;
  right: 2px;
  top: 4px;
}

.funjump-inline-id-amount {
  display: flex;
  justify-content: center;
  align-items: center;
  /* font-size: 13px; */
  font-size: 0.92em;
}

/* Settings up height og loads on large and Ultra Large screen */

@media only screen and (min-device-width: 1921px) and (max-device-width: 2560px) {
  div[id^="innercard"] {
    height: calc(100vh - 200px);
  }
  .show-loads-3 .centerCards div[id^="innercard"] {
    height: calc(100vh - 220px);
  }

  .show-loads-6 .centerCards {
    height: calc(100vh - 145px);
  }
  .show-loads-7 .centerCards,
  .show-loads-8 .centerCards {
    height: calc(100vh - 140px);
  }
}
.is-block {
  display: block;
}
.show-dot-menu {
  margin-right: 5px;
}
.set-position {
  right: 14px;
}

.flex-start {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.regular-btn-styles {
  font-size: 16px;
  margin-bottom: 4px;
  margin-left: 4px;
  padding-left: 8px;
  padding-right: 7px;
}
@media (min-width: 2560px) and (max-width: 3440px) {
  div[id^="innercard"] {
    height: calc(100vh - 245px);
  }
  .menu-option-moreloads {
    width: 180px;
  }
  .squized-btn-styles {
    font-size: 12px;
    margin-bottom: 4px;
    margin-left: 4px;
    padding: 2px;
  }
  .menu-option-moreloads {
    right: 8%;
  }
  .funjumptype {
    padding: 0rem 0rem 0rem 1rem !important;
  }

  .corosal-btn-padding {
    padding: 5px;
  }

  .remove-padding {
    padding: 0rem 0rem 0rem 1rem !important;
    font-weight: bold;
  }

  /* .arrow-left-btn, .arrow-right-btn {
    padding:  5px ;
  } */

  .bottom-btns button {
    font-size: 1em;
  }

  .show-loads-3 .centerCards div[id^="innercard"],
  .show-loads-6 .centerCards div[id^="innercard"] {
    height: calc(100vh - 230px);
  }
  .show-loads-4 .centerCards div[id^="innercard"] {
    height: calc(100vh - 208px);
  }
  .show-loads-5 .centerCards div[id^="innercard"] {
    height: calc(100vh - 215px);
  }
  .show-loads-7 .centerCards div[id^="innercard"],
  .show-loads-9 .centerCards div[id^="innercard"] {
    height: calc(100vh - 200px);
  }
  .show-loads-8 .centerCards div[id^="innercard"] {
    height: calc(100vh - 224px);
  }
  .show-loads-10 .centerCards div[id^="innercard"] {
    height: calc(100vh - 230px);
  }

  .load-header.decHeight {
    height: 82px;
  }
  .right-menu-morethan4 {
    position: absolute;
    right: 0px;
    top: 20px;
    font-size: 20px;
    cursor: pointer;
  }
}
@media only screen and (max-width: 1920px) {
  .widthForMoreThan5{
    width: 54px;
    /* width: 38px; */
  }
  .widthForMoreThan7{
   width:0px;
  }
  .widthForLessThan1920-7{
    width:38px;
  }
}
.css-1hwfws3 svg {
  height:12px !important;
  width:12px !important;
}
.css-1hwfws3 {
  padding: 2px 2px !important;
}




