.mainContainerHeight span:nth-child(2) > div {
  overflow: hidden;
}

/* @media (min-width: 1400px) {
  .show-loads-6 .centerDivFooter, .show-loads-5 .centerDivFooter {
    overflow: visible;
  }
} */

@media (min-width: 1920px) and (max-width: 2274px) {
  .show-loads-1 .centerCards div[id^="innercard"],
  .show-loads-2 .centerCards div[id^="innercard"] {
    height: calc(100vh - 245px);
  }
  .show-loads-4 .centerCards div[id^="innercard"],
  .show-loads-5 .centerCards div[id^="innercard"] {
    height: calc(100vh - 205px);
  }
  .show-loads-3 .centerCards div[id^="innercard"] {
    height: calc(100vh - 265px);
  }
  .show-loads-6 .centerCards div[id^="innercard"],
  .show-loads-7 .centerCards div[id^="innercard"],
  .show-loads-8 .centerCards div[id^="innercard"] {
    height: calc(100vh - 220px);
  }
}
/* TO SHOW 5 IN A ROW */

.show-loads-5 {
  width: 120% !important;
}
.show-loads-5 > div {
  padding: 8px;
}

.show-loads-8 > div,
.show-loads-7 > div,
.show-loads-9 > div,
.show-loads-10 > div {
  padding: 5px;
}
.show-loads-11 > div,
.show-loads-12 > div {
  padding: 4px;
}
/* SHOW-LOADS-2 */

.show-loads-2 .load-header .right-side,
.show-loads-1 .load-header .right-side {
  width: 45%;
}

.show-loads-2 .load-header .plane-img {
  width: 91px;
  height: 25px;
}

.show-loads-2 .load-header .right-side .second {
  /* font-size: 1.3em; */
  font-size: 18px;
}

.show-loads-2 .load-header .small-skydiver {
  width: 40px;
  height: 20px;
}

.show-loads-2 .load-header .third p {
  /* font-size: 1.2rem; */
}

.show-loads-2 {
}

.show-loads-2 {
}

/* SHOW-LOADS-3 */

.show-loads-3 .load-header .plane-img {
  width: 90px;
  height: 25px;
}

.show-loads-3 .load-header .right-side .second {
  /* font-size: 1.3em; */
  font-size: 18px;
}

.show-loads-3 .load-header .third p {
  font-size: 1.1rem;
}

.show-loads-3 .load-header .small-skydiver {
  width: 40px;
  height: 20px;
}

/* SHOW-LOADS-4 */

.show-loads-4 .load-header .plane-img {
  width: 90px;
  height: 25px;
  margin-right: 0;
}

.show-loads-4 .load-header .right-side .second {
  /* font-size: 1.3em; */
  font-size: 18px;
}

/* .show-loads-4 .load-header .third p {
    font-size: 1.1rem;
} */

.show-loads-4 .load-header .small-skydiver {
  width: 40px;
  height: 20px;
}

/* SHOW-LOADS-6 */

.show-loads-6 .load-header .right-side {
  width: 40% !important;
}

.show-loads-6 .load-header .plane-img {
  width: 48px;
  height: 23px;
}

.show-loads-6 .load-header .middle-side .top-text {
  font-size: 25px;
  /* font-size: 1.56em; */
}

.show-loads-6 .load-header .middle-side .bottom-text {
  font-size: 20px;
}

.show-loads-6 .load-header .middle-side .red-text {
  font-size: 21px;
}

.show-loads-6 .load-header .right-side .second {
  /* font-size: 1.3em; */
  font-size: 18px;
}

/* .show-loads-6 .load-header .third p {
    font-size: 0.9rem;
} */

.show-loads-6 .load-header .small-skydiver {
  width: 30px;
  height: 20px;
}

@media (min-width: 1400px) {
  .show-loads-6 .mainContainerHeight {
    padding: 27px 10px;
  }
  .mainContainerHeight .timeRemaining {
    /* font-size: 1.71em; */
    font-size: 20px;
  }
  .show-loads-3 .mainContainerHeight {
    padding: 0 10px;
  }

  .show-loads-2 .mainContainerHeight {
    /* padding-left: 5%;
        padding-right: 5%; */
  }
  .show-loads-4 .load-header .load-no {
    font-size: 45px;
    padding-top: 17%;
  }
  .show-loads-5 .load-header .plane-img {
    width: 70px;
  }
  .show-loads-6 .load-header .load-no {
    padding-top: 40%;
    font-size: 40px;
  }

  .show-loads-5 .load-header .load-no {
    padding-top: 40%;
    font-size: 40px;
  }

  .show-loads-6 .load-header .left-side {
    width: 25%;
  }
  .show-loads-6 .load-header .middle-side {
    width: 40%;
  }
  .show-loads-6 .load-header .right-side {
    width: 35%;
  }
}

@media (min-width: 1700px) {
  .show-loads-6 .load-header .plane-img {
    width: 70px;
  }
  .show-loads-6 .load-header .load-no {
    font-size: 48px;
    padding-top: 20%;
  }
  .show-loads-5 .load-header .plane-img {
    width: 80px;
    height: 22px;
  }
  .show-loads-5 .load-header .right-side .second {
    /* font-size: 1em; */
    font-size: 14px;
  }
  /* .show-loads-5 .load-header .third {
        font-size: 1.2rem;
    } */
  .show-loads-5 .load-header .small-skydiver {
    width: 40px;
    height: 20px;
  }
}

@media (min-width: 1900px) {
  .load-header .left-side .load-no {
    display: flex;
    align-items: center;
    font-size: 40px;
  }

  .load-header .left-side .load-no.load-two {
    font-size: 40px;
  }
  .load-header .left-side .load-no.load-three {
    font-size: 35px;
    margin-top: 20px;
  }
  .load-header .left-side .load-no.load-four {
    font-size: 28px;
  }
  .load-header .left-side .load-no.load-five {
    font-size: 28px;
    padding-top: 12px;
  }
  .load-header .left-side .load-no.load-six {
    font-size: 24px;
  }
  .load-header .left-side .load-no.load-small-size {
    font-size: 18px;
  }
  .load-header .left-side1 .load-no {
    display: flex;
    align-items: center;
    font-size: 25px;
  }
  .load-header .left-side11 .load-no.load-two {
    font-size: 35px;
  }
  .load-header .left-side1 .load-no.load-three {
    font-size: 25px;
  }
  .load-header .left-side1 .load-no.load-four {
    font-size: 28px;
  }
  .load-header .left-side1 .load-no.load-five {
    font-size: 22px;
  }
  .load-header .left-side1 .load-no.load-six {
    font-size: 24px;
  }
  .load-header .left-side1 .load-no.load-small-size {
    font-size: 18px;
  }

  .show-loads-7 .load-header .left-side .load-no.load-small-size {
    margin-top: 22px;
  }

  .show-loads-8 .load-header .left-side .load-no.load-small-size {
    margin-top: 22px;
  }

  .show-loads-8 .load-header .left-side {
    padding-right: 10px;
    padding-left: 0px;
  }
  .load-header .left-side .load-no.load-fi {
    font-size: 35px;
  }
  .show-loads-5 > div {
    padding: 4px;
  }
  .show-loads-6 .load-header .left-side {
    width: 25%;
  }
  .show-loads-4 .load-header .plane-img {
    height: 35px;
  }
  .show-loads-4 .mainContainerHeight {
    padding: 0 4px;
  }
  .show-loads-6 .mainContainerHeight {
    padding: 0px 3px;
  }

  .show-loads-3 .showConfigTruePlanenLoadNum {
    width: 55% !important;
    font-size: 21px;
    padding-top: 8px;
    padding-left: 8px;
  }

  .show-loads-4 .showConfigTruePlanenLoadNum {
    width: 55% !important;
    font-size: 18px;
    padding-top: 8px;
    padding-left: 2px;
  }

  .show-loads-4 .third_showConfigT p {
    text-align: center !important;
    font-weight: 800;
    font-size: 16px !important;
  }

  .show-loads-5 .third_showConfigT p {
    text-align: center !important;
    font-weight: 800;
    font-size: 15px !important;
  }

  .show-loads-6 .third_showConfigT p {
    text-align: center !important;
    font-weight: 800;
    font-size: 15px !important;
  }

  .show-loads-7 .showConfigTruePlanenLoadNum {
    width: 62% !important;
    padding-left: 3px !important;
    font-size: 13px !important;
  }

  .show-loads-7 .third_showConfigT p {
    text-align: center !important;
    font-weight: 800;
    font-size: 11px !important;
  }

  .show-loads-8 .showConfigTruePlanenLoadNum {
    font-size: 10px !important;
    width: 63%;
    padding-left: 2px;
  }

  .show-loads-8 .third_showConfigT p {
    text-align: center !important;
    font-weight: 800;
    font-size: 15px !important;
  }

  .show-loads-6 .load-header .middle-side {
    width: 35%;
  }
  .show-loads-6 .showConfigTruePlanenLoadNum {
    width: 55% !important;
    padding-left: 0px;
    font-size: 15px;
  }
  .show-loads-7 > div,
  .show-loads-8 > div {
    padding: 5px 3px;
  }
  .show-loads-6 .load-header .plane-img {
    width: 50px;
    height: 20px;
  }
  .show-loads-6 .load-header .right-side .second {
    /* font-size: 0.9em; */
    font-size: 13px;
  }
  .show-loads-2 .mainContainerHeight .timeRemaining {
    /* font-size: 1.35em; */
    font-size: 19px;
  }
  .show-loads-4 .load-header .load-no {
    font-size: 50px;
  }
  .show-loads-4 .load-header .middle-side .red-text,
  .show-loads-5 .load-header .middle-side .red-text,
  .show-loads-6 .load-header .middle-side .red-text {
    font-size: 18px;
  }
  .show-loads-5 .load-header .right-side .second-moreloads p,
  .show-loads-4 .load-header .right-side .second-moreloads p {
    font-size: 13px;
  }
  .show-loads-6 .load-header .right-side .second-moreloads p {
    font-size: 12px;
  }

  .showConfigTruePlanenLoadNum {
    display: flex;
    align-items: center;
  }

  .show-loads-1 .showConfigTruePlanenLoadNum {
    padding-left: 3px !important;
    font-size: 18px;
    padding-top: 10px;
  }

  .show-loads-2 .showConfigTruePlanenLoadNum {
    padding-left: 3px !important;
    font-size: 18px;
    padding-top: 9px;
  }
}

@media (min-width: 2560px) and (max-width: 3440px) {
  .show-loads-4 .centerCards {
    height: calc(100vh - 125px);
  }
  .show-loads-6 .showConfigTruePlanenLoadNum {
    width: 37% !important;
  }

  .show-loads-8 .showConfigTruePlanenLoadNum {
    font-size: 16px !important;
    width: 63%;
    padding-left: 0px;
  }

  .show-loads-1 .showConfigTruePlanenLoadNum {
    padding-left: 17px !important;
    font-size: 33px;
    padding-top: 10px;
  }

  .show-loads-1 .third_showConfigT p {
    text-align: center !important;
    font-weight: 800;
    font-size: 28px !important;
  }

  .show-loads-2 .showConfigTruePlanenLoadNum {
    padding-left: 12px !important;
    font-size: 33px;
    padding-top: 9px;
  }

  .show-loads-2 .third_showConfigT p {
    text-align: center !important;
    font-weight: 800;
    font-size: 28px !important;
  }

  .show-loads-3 .showConfigTruePlanenLoadNum {
    width: 55% !important;
    font-size: 32px;
    padding-top: 8px;
    padding-left: 8px;
  }

  .show-loads-3 .third_showConfigT p {
    text-align: center !important;
    font-weight: 800;
    font-size: 30px !important;
  }

  .load-header .left-side .load-no.load-three {
    font-size: 35px;
    margin-top: 4px;
  }

  .load-header .left-side1 .load-no.load-three {
    font-size: 25px;
    padding-top: 0px;
  }

  .load-header .left-side .load-no.load-four {
    font-size: 34px;
  }
  .show-loads-4 .showConfigTruePlanenLoadNum {
    width: 55% !important;
    font-size: 27px;
    padding-top: 8px;
    padding-left: 2px;
  }

  .show-loads-4 .third_showConfigT p {
    text-align: center !important;
    font-weight: 800;
    font-size: 25px !important;
  }

  .show-loads-5 .load-header .load-no {
    padding-top: 15% !important;
    font-size: 30px !important;
  }

  .show-loads-5 .showConfigTruePlanenLoadNum {
    width: 55% !important;
    font-size: 22px;
    padding-top: 8px;
    padding-left: 2px;
  }

  .show-loads-5 .third_showConfigT p {
    text-align: center !important;
    font-weight: 800;
    font-size: 24px !important;
  }

  .load-header .left-side .load-no.load-six {
    font-size: 34px;
  }

  .show-loads-6 .showConfigTruePlanenLoadNum {
    width: 55% !important;
    font-size: 22px;
    padding-top: 8px;
    padding-left: 2px;
  }

  .show-loads-6 .third_showConfigT p {
    text-align: center !important;
    font-weight: 800;
    font-size: 24px !important;
  }

  .show-loads-6 .load-header .load-no {
    padding-top: 16%;
  }

  .show-loads-7 .load-header .left-side .load-no.load-small-size {
    font-size: 35px;
    padding-top: 10px;
    margin-top: 10px;
  }

  .show-loads-7 .showConfigTruePlanenLoadNum {
    width: 55% !important;
    font-size: 19px !important;
    padding-top: 8px;
    padding-left: 0px !important;
  }

  .show-loads-7 .third_showConfigT p {
    text-align: center !important;
    font-weight: 800;
    font-size: 16px !important;
  }

  .show-loads-8 .load-header .left-side {
    padding-right: 0px;
    padding-left: 10px;
    padding-bottom: 15px;
  }

  .show-loads-8 .load-header .left-side .load-no.load-small-size {
    font-size: 35px;
    padding-top: 10px;
  }

  .show-loads-9 .load-header .left-side .load-no.load-small-size {
    font-size: 32px;
  }

  .show-loads-10 .load-header .left-side .load-no.load-small-size {
    font-size: 30px;
  }

  .show-loads-10 .load-header .left-side {
    padding-left: 0px;
  }
}
