/* Right menu style */
.checkin-active-staff-container {
  margin-top: 10px;
}

.staff-info-box {
  font-size: 15px !important;
  margin: 10px 0;
}

.draggable-staff-name-btn {
  padding: 0px 5px;
  font-size: 18px;
}
