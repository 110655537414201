.store-container {
  position: relative;
}

.store-container .main-div {
  height: 92vh;
  overflow-y: auto;
}

.store-container table {
  margin: 0 auto 20px auto;
  background-color: #e7f2f6;
  width: 90%;
}

.store-container table .main-heading {
  letter-spacing: 1px;
  color: #e7f2f6;
  background-color: #1c6bcf;
}

.store-container table .main-heading.tandem {
  /* for tandem pacakges */
  font-size: 12px;
}

/* Calender Events styles */
.middle-title {
  display: flex;
  justify-content: center;
  align-items: center;
}
