.longfazers,
#kapowsin {
  /* background-color: #39c5f3;  */
  background-color: #00bfff;
}

.loadingContainer {
  position: absolute;
  /* with new theme */
  height: 100vh;
  /* height: 100%; */
  width: 100%;
  z-index: 9999;
  overflow: hidden;
  top: 0;
}

.loading {
  font-size: 2rem !important;
  position: absolute;
  text-align: center;
  z-index: 9999;
  top: 60%;
  left: 47%;
  margin-left: -30px;
  color: #fff;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 200;
  letter-spacing: 1px;
  font-size: 13px;
  text-transform: uppercase;
}
.skydiver {
  position: absolute;
  z-index: 99999;
  top: 50%;
  margin-top: -120px;
  margin-left: -75px;
  left: 50%;
  animation: speeder 0.4s linear infinite;
}
.skydiver > img {
  position: relative;
  z-index: 9999;
}
.skydiver > span.left {
  height: 35px;
  width: 15px;
  position: absolute;
  top: 33px;
  left: 22px;
  z-index: 999;
}
.skydiver > span.left-hand {
  height: 50px;
  width: 2px;
  position: absolute;
  top: 80px;
  left: 5px;
  z-index: 999;
}
.skydiver > span.right {
  height: 35px;
  width: 15px;
  position: absolute;
  top: 0px;
  left: 120px;
  z-index: 999;
}
.skydiver > span.right-hand {
  height: 50px;
  width: 2px;
  position: absolute;
  top: 80px;
  right: 5px;
  z-index: 999;
}
.skydiver > span > span:nth-child(1),
.body > span > span:nth-child(2),
.body > span > span:nth-child(3),
.body > span > span:nth-child(4) {
  width: 5px;
  height: 30px;
  background: #fff;
  position: absolute;
  animation: fazer1 0.2s linear infinite;
}
.skydiver > span.left-hand > span:nth-child(1),
.body > span > span:nth-child(2),
.body > span > span:nth-child(3),
.body > span > span:nth-child(4),
.skydiver > span.right-hand > span:nth-child(1),
.body > span > span:nth-child(2),
.body > span > span:nth-child(3),
.body > span > span:nth-child(4) {
  width: 2px;
  height: 50px;
  background: #fff;
  position: absolute;
  animation: fazer2 0.2s linear infinite;
}

.skydiver > span > span:nth-child(2) {
  left: 3px;
  animation: fazer2 0.4s linear infinite;
}
.skydiver > span > span:nth-child(3) {
  left: 1px;
  animation: fazer3 0.4s linear infinite;
  animation-delay: -1s;
}
.skydiver > span > span:nth-child(4) {
  left: 4px;
  animation: fazer4 1s linear infinite;
  animation-delay: -1s;
}
@keyframes fazer1 {
  0% {
    top: 0;
  }
  100% {
    top: -150px;
    opacity: 0;
  }
}
@keyframes fazer2 {
  0% {
    top: 0;
  }
  100% {
    top: -100px;
    opacity: 0;
  }
}
@keyframes fazer3 {
  0% {
    top: 0;
  }
  100% {
    top: -50px;
    opacity: 0;
  }
}
@keyframes fazer4 {
  0% {
    top: 0;
  }
  100% {
    top: -300px;
    opacity: 0;
  }
}
.longfazers {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 999;
}
.longfazers span {
  position: absolute;
  width: 3px;
  height: 20%;
  background: #fff;
}
.longfazers span:nth-child(1) {
  left: 40%;
  animation: lf 0.6s linear infinite;
  animation-delay: -5s;
}
.longfazers span:nth-child(2) {
  left: 45%;
  animation: lf2 0.8s linear infinite;
  animation-delay: -1s;
}
.longfazers span:nth-child(3) {
  left: 50%;
  animation: lf3 0.6s linear infinite;
}
.longfazers span:nth-child(4) {
  left: 65%;
  animation: lf4 0.5s linear infinite;
  animation-delay: -3s;
}
.longfazers span:nth-child(5) {
  left: 35%;
  animation: lf5 0.5s linear infinite;
  animation-delay: -1.3s;
}
.longfazers span:nth-child(6) {
  left: 75%;
  animation: lf6 0.5s linear infinite;
  animation-delay: -1s;
}
@keyframes speeder {
  0% {
    transform: translate(2px, 1px) rotate(0deg);
  }
  10% {
    transform: translate(-1px, -3px) rotate(-1deg);
  }
  20% {
    transform: translate(-2px, 0px) rotate(1deg);
  }
  30% {
    transform: translate(1px, 2px) rotate(0deg);
  }
  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    transform: translate(-1px, 3px) rotate(-1deg);
  }
  60% {
    transform: translate(-1px, 1px) rotate(0deg);
  }
  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }
  80% {
    transform: translate(-2px, -1px) rotate(1deg);
  }
  90% {
    transform: translate(2px, 1px) rotate(0deg);
  }
  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}
@keyframes lf {
  0% {
    top: 200%;
  }
  100% {
    top: -200%;
    opacity: 0;
  }
}
@keyframes lf2 {
  0% {
    top: 200%;
  }
  100% {
    top: -200%;
    opacity: 0;
  }
}
@keyframes lf3 {
  0% {
    left: 200%;
  }
  100% {
    top: -100%;
    opacity: 0;
  }
}
@keyframes lf4 {
  0% {
    top: 200%;
  }
  100% {
    top: -100%;
    opacity: 0;
  }
}
@keyframes lf5 {
  0% {
    top: 300%;
  }
  100% {
    top: -100%;
    opacity: 0;
  }
}
@keyframes lf6 {
  0% {
    top: 100%;
  }
  100% {
    top: -300%;
    opacity: 0;
  }
}
#dizio {
  position: absolute;
  bottom: 0%;
  left: 50%;
  margin: 0 0 0 -97px;
  z-index: 9999;
  height: 62px;
}
#kapowsin {
  /* margin:20px auto; */
  padding: 20px 0;
  text-align: center;
}
#kapowsin img {
  width: 300px;
}

.fade-out-loader {
  animation: fadeout 2s linear;
}
@keyframes fadeout {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@media (max-width: 576px) {
  #kapowsin img {
    width: 250px;
  }
  .skydiver {
    margin-left: -66px;
  }
  .skydiver > img {
    width: 120px;
  }
  .loading {
    font-size: 1.5rem !important;
    left: 42%;
  }
}
