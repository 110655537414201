.donut-container {
  position: relative;
  height: 60px;
}

.inreports.report-loading {
  position: absolute;
  z-index: 999999;
  margin-left: 45%;
  margin-top: 10%;
}

/* in /load-manager in manifest */

.inloadmanager.donut-container {
  position: absolute;
  height: 60px;
  left: 50%;
  top: 40%;
}

.sample1.donut-container {
  top: 50%;
}

.store.donut-container {
  position: absolute;
  top: 45vh;
}

.accounts.donut-container {
  top: 50vh;
  left: 50%;
}

.donut-container .donut > img {
  width: 60px;
  height: 60px;
}

.donut {
  position: absolute;
  z-index: 99999;
  top: 50%;
  margin-top: -45px;
  margin-left: -45px;
  left: 50%;
  animation: rotating 4s linear infinite;
}

.donut > img {
  position: relative;
  z-index: 9999;
}

.jumperStat {
  position: absolute;
  left: 50%;
  top: 25%;
}

@-webkit-keyframes rotating
/* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
  to {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}

@keyframes rotating {
  from {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
  to {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}

.rotating {
  -webkit-animation: rotating 4s linear infinite;
  -moz-animation: rotating 4s linear infinite;
  -ms-animation: rotating 4s linear infinite;
  -o-animation: rotating 4s linear infinite;
  animation: rotating 4s linear infinite;
}

#logo {
  position: absolute;
  bottom: 0;
  left: 50%;
  margin: 0 0 0 -97px;
  height: 62px;
}

@media (min-width: 576px) {
  .inreports.report-loading {
    position: absolute;
    z-index: 999999;
    margin-left: 46%;
    margin-top: 6%;
  }
  .donut-container .donut > img {
    width: 90px;
    height: 90px;
  }
}
