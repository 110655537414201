.closable-container {
  margin-bottom: 15px;
  border: 2px dashed;
  padding: 5px;
}

.btns-container label {
  font-weight: 600;
  display: block;
}

.btns-container .select-container > div {
  display: flex;
  justify-content: space-evenly;
}

.btns-container .action-container {
  display: flex;
}

.bookingsBtn {
  background: none;
  border: 1px solid lightgray;
  border-radius: 5px;
  padding: 2px;
  color: #007bff;
}

.btns-container .action-container button {
  margin-left: 4px;
}
.btns-container .select-container select {
  width: 65%;
  padding: 0px;
  font-size: 0.9rem;
  font-weight: 500;
}

.btns-container .select-container button {
  padding: 0 0.3rem;
}

.btns-container .dialog-container {
  /* relative to col-md-4 */
  position: absolute;
  z-index: 9999;
  background: blue;
  padding: 10px;
  border-radius: 10px;
}

.btns-container .dialog-container label,
.btns-container .dialog-container h5 {
  color: white;
}

.tabContentCon .rightColumnCards .handle-font {
  font-size: 0.9rem;
}

@media (max-width: 768px) {
  .btns-container .select-container select {
    width: 60%;
  }
  .btns-container .select-container button {
    padding: 0 1rem;
  }
}
