#modal-reservation {
  position: relative;
}

#modal-reservation .close {
  position: absolute;
  right: 20px;
}
.reservation-details-modal .update-btns {
  position: absolute;
  top: 0;
  right: 0px;
}
.reservation-details-modal .update-btns button {
  color: blue;
  border: none;
  background: white;
  margin-right: 4px;
  font-size: 20px;
}

.styled-scroll::-webkit-scrollbar-track {
  --webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

.styled-scroll::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}

.styled-scroll::-webkit-scrollbar-thumb {
  border-radius: 10px;
  --webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #555;
}

@media (min-width: 576px) {
  #modal-reservation {
    max-width: 700px;
  }
}
