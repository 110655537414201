.load-header {
  display: flex;
  background-color: white;
}

/* school waitlist  */

.load-header.school {
  height: 50px;
}

.load-header > h3 {
  font-weight: 600;
  margin: auto;
}

.load-header.geared-up {
  background-color: black;
}

.load-header .left-side {
  width: 25%;
  background-size: 100% 100%;
  background-image: url("../../images/blackHeader.png");
}

.load-header .left-side1 {
  width: 25%;
  margin-left: auto;
  background-size: 100% 100%;
}

.load-header .left-side1 .load-no {
  display: flex;
  align-items: center;
  font-size: 40px;
  margin-left: 25%;
  font-family: "Exo 2";
  font-weight: 900;
  color: white;
}

.left-header .left-side.flex-class {
  display: flex !important;
}

.load-header.geared-up .left-side {
  background-image: url("../../images/whiteheader.png");
}

.load-header .left-side .load-no {
  display: flex;
  align-items: center;
  font-size: 40px;
  margin-left: 25%;
  font-family: "Exo 2";
  font-weight: 900;
  color: white;
}

.load-header.geared-up .left-side .load-no {
  color: black;
}

.load-header .middle-side {
  background-color: white;
  color: black;
  width: 60%;
  font-weight: 800;
  line-height: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.load-header.geared-up .middle-side {
  background-color: black;
  color: white;
}

.load-header .middle-side p {
  text-align: center;
}

.load-header .middle-side .top-text {
  font-size: 31px;
}

.load-header .middle-side .top-text-moreloads {
  font-size: 18px;
}
.load-header .middle-side .top-text-moreloads-completed {
  font-size: 15px;
}

.load-header .middle-side .red-text {
  font-size: 25px;
  color: red;
}

.load-header .middle-side .green-text {
  font-size: 25px;
  color: green;
}
.load-header .middle-side .small-font {
  font-size: 18px;
}

.load-header .middle-side .bottom-text {
  font-size: 25px;
}

.load-header .right-side {
  width: 35%;
  background-image: url("../../images/blueheader.png");
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  /* align-items: flex-end; */
  align-items: center;
  color: white;
  position: relative;
}

.load-header .right-side p,
.load-header .middle-side p {
  margin: 0;
}

.load-header .right-side .first {
  /* display: flex; */
}

.load-header .right-side .first button {
  padding: 0 2px;
  font-size: 19px;
  background: white;
  color: #0c2693;
  border: 0;
}

.load-header .right-side .plane-img {
  background-image: url("../../images/plane.png");
  background-size: 100% 100%;
  width: 80px;
  height: 20px;
  margin-right: 6%;
}

.load-header .right-side .second p {
  text-align: center;
  background: white;
  color: black;
  font-size: 15px;
  border-radius: 12px;
  padding: 0px 2px 2px 2px;
}

.load-header .right-side .second-moreloads p {
  text-align: center;
  background: white;
  color: black;
  font-size: 14px;
  border-radius: 12px;
  padding: 0px 2px 0px 2px;
}

.load-header .right-side .third {
  width: 70%;
  align-items: center;
  /* justify-content: space-evenly; */
  font-size: 16px;
}

.load-header .right-side .third_showConfigT {
  width: 70%;
  align-items: center;
  justify-content: space-evenly;
  font-size: 18px;
}
.load-header .right-side .third_showConfigT p {
  text-align: center;
  font-weight: 800;
  font-size: 22px;
}

.third-loadmore {
  font-size: 12px;
}

.load-header .right-side .third p {
  text-align: center;
  font-weight: 800;
}

.load-header .small-skydiver {
  background-image: url("../../images/jumper.png");
  width: 30px;
  height: 18px;
}

.editable-timer .show-time-with-btn {
  display: flex;
  justify-content: center;
  align-items: center;
}

.editable-timer .show-time-with-btn .timer {
  padding: 0 8px;
}

.load-header .menu-header-option {
  font-size: 14px;
}
.right-side .top-conatiner {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
}

@media (max-width: 500px) {
  .load-header {
    height: 80px;
  }
  .load-header .left-side .load-no {
    font-size: 45px;
  }
  .load-header .middle-side .top-text {
    font-size: 24px;
  }
  .load-header .middle-side .red-text,
  .load-header .middle-side .bottom-text {
    font-size: 18px;
  }
  .load-header .right-side .plane-img {
    width: 50px;
  }
  .load-header .right-side .second p {
    font-size: 12px;
    padding: 0px 3px 1px 3px;
  }
  .load-header .right-side .third {
    width: 90%;
  }
  /* HEADER MENU */
  .load-header .menu-header-option {
    top: 4%;
    right: 11%;
    width: 180px;
  }
  .load-header .menu-header-option li {
    padding: 5px;
    font-size: 14px;
    font-weight: 600;
  }
}

@media only screen and (min-device-width: 1920px) and (max-device-width: 2199px) {
  .load-header .right-side .first button {
    padding: 0 2px;
    font-size: 12px;
    background: white;
    color: #0c2693;
    border: 0;
  }

  .load-header .left-side {
    padding-left: 2px;
    width: 25%;
    background-size: 100% 100%;
    background-image: url("../../images/blackHeader.png");
  }

  .load-header .right-side .second-moreloads p {
    font-size: 10px;
  }

  .show-loads-5 .showConfigTruePlanenLoadNum {
    padding-left: 0px !important;
    font-size: 17px;
    width: 55%;
    padding-top: 11px;
  }

  .show-loads-5 .load-header .right-side {
    width: 38% !important;
  }

  .show-loads-8 .third-loadmore,
  .show-loads-7 .third-loadmore {
    font-size: 11px;
  }

  .show-loads-1 .left-side1 .load-no {
    font-size: 20px;
  }

  .show-loads-8 .left-side1 p {
    font-size: 14px !important;
  }

  .show-loads-5 .load-header .middle-side {
    width: 37% !important;
  }

  .show-loads-7 .load-header .middle-side {
    width: 52% !important;
  }
}
@media (min-width: 2560px) and (max-width: 3440px) {
  .load-header .right-side .second p {
    font-size: 18px;
  }
  .load-header .right-side .second-moreloads.ten-loads p {
    font-size: 12px;
  }
  .left-side.flex-class {
    display: flex;
  }

  .load-header .left-side1 .load-no {
    margin-left: 38%;
  }

  .load-header .left-side {
    padding-left: 10px;
    width: 35%;
    background-size: 100% 100%;
    background-image: url("../../images/blackHeader.png");
  }

  .show-loads-3 .load-header .left-side {
    padding-top: 15px;
  }

  .show-loads-4 .showConfigTruePlanenLoadNum {
    padding-left: 11px;
    width: 30% !important;
  }

  .show-loads-6 .showConfigTruePlanenLoadNum {
    width: 35% !important;
  }

  .show-loads-7 .third_showConfigT p {
    font-size: 12px !important;
  }

  .show-loads-8 .showConfigTruePlanenLoadNum {
    font-size: 13px !important;
    width: 47% !important ;
    padding-left: 1px !important;
  }

  .show-loads-8 .load-header .left-side .load-no {
    margin-left: 13%;
  }

  .show-loads-9 .showConfigTruePlanenLoadNum {
    font-size: 15px !important;
    width: 59%;
    padding-left: 0px;
  }
  .show-loads-10 .showConfigTruePlanenLoadNum {
    font-size: 13px !important;
    width: 54%;
    padding-left: 2px;
  }

  .show-loads-9 .third_showConfigT p {
    font-size: 13px !important;
  }
  .show-loads-10 .third_showConfigT p {
    font-size: 12px !important;
  }

  .show-loads-10 .left-side1 p {
    font-size: 15px !important;
  }

  .show-loads-7 .load-header .middle-side {
    width: 38% !important;
  }
  .show-loads-8 .load-header .middle-side {
    width: 34% !important;
  }

  .show-loads-9 .load-header .middle-side {
    width: 40% !important;
  }

  .show-loads-9 .load-header .left-side {
    padding-left: 0px;
  }

  .show-loads-10 .load-header .middle-side {
    width: 40% !important;
  }
}
