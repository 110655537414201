.qrCode {
  margin: 2px;
  padding: 10px;
  padding-top: 20px;
  border: 1px solid;
  border-color: black;
  width: 288px;
  height: 288px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  /* justify-content: center; */
  -ms-flex-direction: column;
  flex-direction: column;
  overflow: hidden;
}

.mainContainer p {
  margin: 0;
}
.mainPage {
  background-color: white;
  padding: 84px 114px;
  width: 816px;
  height: auto;
  display: flex;
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
}

.pageContainer {
  height: 1056px;
  background: white;
}

.mainContainer {
  display: flex;
  height: 100px;
  font-size: 15px;
  /* align-items: center; */
  -ms-flex-pack: distribute;
  justify-content: flex-start;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  margin-left: 5px;
  margin-top: 5px;
}

.qrContainer {
  margin: 0 !important;
  width: 171px !important;
}

.buttonsOut {
  text-align: center;
}

.buttonMain {
  margin: 20px;
  width: 150px;
  height: 38px;
  border-radius: 5px;
  background: #59c5dc;
  color: white;
}
.errorMsg {
  color: red;
}

.tableOut {
  overflow: auto;
  width: calc(100vw - 400px);
  margin-left: 5%;
}
