* {
  padding: 0;
  margin: 0;
}

.ViewAccounts-footer-251 {
  width: 90% !important;
  margin: 10px 1%;
  /* bottom: 0; */
  padding: 7px 0px;
  position: absolute;
  margin-left: 10px;
}

.ViewAccounts-totalSection-246 {
  color: #e7f2f6;
  height: 55px;
  margin: 10px auto;
  border: 1px solid #e7f2f6;
  display: flex;
  align-items: center;
  border-left: 0;
  border-right: 0;
  text-transform: capitalize;
}

.MuiTypography-colorInherit-531 {
  color: inherit;
}

.DataTable-paper-152 {
  width: 86%;
  border: 0;
  height: 85% !important;
  margin: 2% auto;
  min-width: 333px;
  max-height: 85% !important;
  overflow-y: auto;
  border-radius: 3px;
  background-color: #e7f2f6;
}
.middleDataContainer {
    height: 100% !important;
}

.MuiTypography-subheading-509 {
  color: rgba(0, 0, 0, 0.87);
  font-size: 1rem;
  font-weight: 400;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  line-height: 1.5em;
}

.MuiTypography-root-502 {
  margin: 0;
  display: block;
}

.ViewAccounts-itemBoxContainer-249 {
  margin: 0px 10px;
  display: grid;
  padding: 5px 0px;
  grid-column-gap: 10px;
  grid-template-columns: 1fr auto;
}

.MuiButton-root-352.MuiButton-disabled-372 {
  color: rgba(0, 0, 0, 0.26);
}

.MuiButtonBase-root-378.MuiButtonBase-disabled-379 {
  cursor: default;
  pointer-events: none;
}

.ViewAccounts-button-243 {
  width: 100%;
  color: #082764 !important;
  height: 55px;
  margin: 10px auto !important;
  font-size: 18px !important;
  font-weight: 600 !important;
  background-color: #a7f6ff !important;
}

.MuiButton-root-352 {
  color: rgba(0, 0, 0, 0.87);
  padding: 8px 16px;
  font-size: 0.875rem;
  min-width: 64px;
  box-sizing: border-box;
  min-height: 36px;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-weight: 500;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  border-radius: 4px;
  text-transform: uppercase;
}

.MuiButtonBase-root-378 {
  color: inherit;
  border: 0;
  margin: 0;
  cursor: pointer;
  display: inline-flex;
  outline: none;
  padding: 0;
  position: relative;
  align-items: center;
  user-select: none;
  border-radius: 0;
  vertical-align: middle;
  justify-content: center;
  -moz-appearance: none;
  text-decoration: none;
  background-color: transparent;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
}

.MuiButton-label-353 {
  width: 100%;
  display: inherit;
  align-items: inherit;
  justify-content: inherit;
}

.MuiTypography-body1-10 {
  color: #fff;
  font-size: 0.875rem;
  font-weight: 400;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  line-height: 1.46429em;
  text-align: center;
  padding-left: 22px;
  padding-top: 14px;
}

th.MuiTableCell-root-178.MuiTableCell-body-180.DetailByCategory-detailSubHeadings-241 {
  font-size: 12px;
  padding: 12px 0 4px 0;
  border-bottom: 0.5px solid rgba(88, 88, 140, 0.2);
  font-weight: 700;
}

td.MuiTableCell-root-178.MuiTableCell-body-180.DetailByCategory-detailtableCell-242 {
  font-size: 12px;
  padding: 10px 2px;
}

table#print-report {
  margin: 10px;
  width: 97%;
}

#modal-report td, #modal-report th {
    font-size: 16px;
    padding: 7px 6px 10px ;
}

#modal-report .tandeGroupData td{
    padding: 5px 6px;
}

table.bookings-table-right {
  max-width: 200px;
}

.bookings-table-right > tr td:nth-child(2) {
  text-align: right;
}

.bookings-table-right > tr:last-child {
  border-top: 1px solid;
}

/* Giftcard resend email modal */

.giftcard-resend-modal {
  font-size: 0.9rem;
}

.giftcard-resend-modal form small {
  color: red;
}

@media (min-width: 576px) {
  .giftcard-resend-modal {
    font-size: 1.1rem;
    max-width: 552px;
  }
}

@media (min-width: 767px) {
  .giftcard-resend-modal {
    font-size: 1.2rem;
  }
}
