.authentication-main {
  background-size: cover !important;
  background-repeat: no-repeat !important;
  height: 100vh !important;
  font-family: work-Sans, sans-serif;
  color: #313131;
  padding: 0 15px;
}
.authentication-main .auth-innerright {
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}
.authentication-box {
  margin-top: 0 !important;
  width: 460px;
  z-index: 2;
  margin: 0 auto;
}
.authentication-box img {
  width: 50%;
}
.authentication-box .card {
  border-radius: 8px;
}
.authentication-main .auth-cardbody {
  padding: 30px !important;
}
.authentication-box h4 {
  color: #313131;
  font-weight: 600;
  letter-spacing: 1px;
}
.authentication-box h6 {
  color: #777777;
  letter-spacing: 1px;
  font-size: 14px;
}
.authentication-box .theme-form {
  padding-top: 20px;
}
.authentication-box .btn-primary {
  background-color: #4466f2;
  border-color: #4466f2;
}
.authentication-box .theme-form .form-group .col-form-label {
  color: #999;
}
