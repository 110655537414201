.hideShowRightMenu {
  position: absolute;
  left: -20px;
  background: #00419d;
  padding: 10px;
  border-radius: 12px 0px 0px 12px;
  top: 10px;
  cursor: pointer;
  width: 22px;
}
.hiddenRightMenuContainer {
  position: absolute !important;
  right: 0px;
  max-width: 5px !important;
}
